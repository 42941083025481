import React from "react";
import { Wrapper } from "../components";
import Sustainabilities from "../components/Sustainabilities/Sustainabilities";
import { SustainData } from "../data/SustainData";
import { Footer, Header } from "../layout";

const Sustainability = () => {
    return (
        <Wrapper id="sustainability" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}

            <div className="container">
                <h2 className="text-display text-uppercase text-center py-5">Sustainability</h2>
                <div className="row">
                    {SustainData.map((d) => (
                        <div className="col-lg-6 item-padding" key={d.id}>
                            <Sustainabilities sustainPercentage={d.sustainPercentage} sustainInfo={d.sustainInfo} />
                        </div>
                    ))}
                </div>
            </div>

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Sustainability;
