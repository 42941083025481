import React from "react";
import { Fade } from "../../animation";
const Wrapper = ({ id, children, pageKey }) => {
    return (
        <section id={id}>
            <Fade uqkey={pageKey}>{children}</Fade>
        </section>
    );
};

export default Wrapper;
