import React from "react";
import { GoMilestone } from "react-icons/go";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { FloatingSocial, Wrapper } from "../components";
import { JourneyData } from "../data/JourneyData";
import { Footer, Header } from "../layout";

const Journey = () => {
    return (
        <Wrapper id="awards" pageKey="vbridge__home__page">
            {/* header */}
            <Header />
            {/* header end */}

            <h2 className="text-center text-display">Our Journey</h2>
            <div className="timeline-wrapper">
                <VerticalTimeline>
                    {JourneyData.map((jd) => (
                        <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            contentStyle={{ background: `#${jd.color}`, color: "#fff" }}
                            contentArrowStyle={{ borderRight: `7px solid  #${jd.color}` }}
                            date=""
                            iconStyle={{
                                background: `#${jd.color}`,
                                color: "#fff",
                                borderRadius: "0",
                                transform: "rotate(-45deg) skew(20deg, 20deg)",
                            }}
                            key={jd.id}
                        >
                            <h3 className="text-heading">{jd.name}</h3>
                            <h4 className="text-subheading">{jd.date}</h4>
                            <p className="text-paragraph">{jd.desc}</p>
                        </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
            </div>

            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Journey;
