import React from "react";
import { CommunityWorks, FloatingSocial, SideCard, Wrapper } from "../components";

import { Footer, Header } from "../layout";

const SocialWorks = () => {
    return (
        <Wrapper id="social" pageKey="vbridge__social__works__page">
            {/* header */}
            <Header />
            {/* header end */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <CommunityWorks />
                    </div>

                    {/* TODO: data can found in pptx file, have to ask or understand */}
                    {/* <div className="col-lg-4">
                        <h2 className="text-center text-heading">Social Works</h2>
                        <SideCard />
                    </div> */}
                </div>
            </div>
            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default SocialWorks;
