import { BsBuilding } from "react-icons/bs";
import { MdOutlineMiscellaneousServices } from "react-icons/md";

export const ServicesData = [
    {
        id: "ser1",
        name: "ARCHITECTURE",
        icon: <MdOutlineMiscellaneousServices />,
    },
    {
        id: "ser2",
        name: "INTERIOR",
        icon: <MdOutlineMiscellaneousServices />,
    },
    {
        id: "ser3",
        name: "TOWNSHIP",
        icon: <MdOutlineMiscellaneousServices />,
    },
    {
        id: "ser3",
        name: "MODULAR",
        icon: <MdOutlineMiscellaneousServices />,
    },
    {
        id: "ser3",
        name: "INSTALLATION",
        icon: <MdOutlineMiscellaneousServices />,
    },
];
