import React from "react";
import { CareerForm, CareerWorks, ContactForm, FloatingSocial, Iframe, Service, ServiceForm, Wrapper } from "../components";
import { ServicesData } from "../data/ServicesData";
import { Footer, Header } from "../layout";

const Services = () => {
    return (
        <Wrapper id="services" pageKey="vbridge__career__page">
            {/* header */}
            <Header />
            {/* header end */}

            {/* services */}
            <div className="container">
                <h1 className="mt-4 text-center text-display text-uppercase">Get Our services</h1>
                {/* <div className="row my-5"> */}
                <div className="flex-box-horizontal my-5">
                    {ServicesData.map((d) => (
                        <div key={d.id}>
                            {/* <div className="col-lg-2 item-padding" key={d.id}> */}
                            <Service name={d.name} icon={d.icon} />
                        </div>
                    ))}
                </div>
                <div className="col-lg-12 item-padding mt-5">
                    <ServiceForm />
                </div>
                {/* </div> */}
            </div>
            {/* services end */}

            {/* career works */}
            {/* <CareerWorks /> */}
            {/* career works end */}

            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Services;
