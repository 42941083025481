import {
    team__Anamitra_Sur,
    team__Ayan_Roy,
    team__Diganta_De,
    team__Dipen_Panda,
    team__Parna_Biswas,
    team__Pradip_Majumder,
    team__Saptarshi_Mondal,
    team__Soumen_Mondal,
    team__Soumyodeep_Das,
    team__Tanmoy_Ghosh,
    team__asis_basu,
} from "../helpers/Images";

export const Team_Core_Data = [
    {
        name: "Ayan Roy",
        main_role: "Co-Founder",
        extra_role: "DIRECTOR | VBRIDGE",
        img: team__Ayan_Roy,
        info: "Ayan Roy is the Co-Founder & Director of VBRIDGE Engineering & Projects Private Limited. He is also the Principal Architect of VBRIDGE Partners as well. He completed his B.Arch from IIEST Shibpur in the year 2017. He is the first prize winner of the coveted Confederation of Indian Industries (CII) - Indian Green Building Council (IGBC) Award (2016) for sustainable design & business model. Awarded by Mr. Suresh Prabhu (the Railway Minister then), he also won the Nippon Paints Asia Young Designer Award (2017) regionally & represented India in Top 12. Parallely he has won several other prestigious awards & recognitions before his venture VBRIDGE, like, the Transparence 2015, ITC Russel Bustle 2016, ZONASA Convention Trophy 2015 & much more. For him Architecture is his passion & helps him to the Community.",
    },
    {
        name: "Soumyodeep Das",
        main_role: "Co-Founder",
        extra_role: "DIRECTOR | VBRIDGE",
        img: team__Soumyodeep_Das,
        info: "For him Architecture is spiritual, personal, intuitive and unending. The creation of the universe is also an architecture, it is not limited within structures. Creating a space which is proper from all aspects is true architecture. It allows him to transform his childhood into his career. Architecture and sports both are the same for him. He completed his B.Arch from IIEST Shibpur in the year 2017.",
    },
    {
        name: "Ar. Ashish Basu ",
        main_role: "Advisor",
        extra_role: "",
        img: team__asis_basu,
    },
    {
        name: "Ar. Anamitra Sur",
        main_role: "Architect",
        extra_role: "",
        img: team__Anamitra_Sur,
    },
    {
        name: "Ar. Saptarshi Mondal",
        main_role: "Architect",
        extra_role: "Urban Planner",
        img: team__Saptarshi_Mondal,
    },
    {
        name: "Dip. Ar. Parna Biswas",
        main_role: "Diploma Architect",
        extra_role: "",
        img: team__Parna_Biswas,
    },
    {
        name: "Tanmoy Ghosh",
        main_role: "Accountant",
        extra_role: "",
        img: team__Tanmoy_Ghosh,
    },

    {
        name: "Diganta De",
        main_role: "Intern Architect",
        extra_role: "",
        img: team__Diganta_De,
    },
    {
        name: "Dip. Er. Dipen Panda",
        main_role: "Site Engineer",
        extra_role: "",
        img: team__Dipen_Panda,
    },
    {
        name: "Pradip Majumder",
        main_role: "Ecosystem Support",
        extra_role: "",
        img: team__Pradip_Majumder,
    },
    {
        name: "Soumen Mondal",
        main_role: "Ecosystem Support",
        extra_role: "",
        img: team__Soumen_Mondal,
    },
];
export const Team_partners_Data = [
    {
        name: "Kaushik Sengupta",
        companey: "KSG PROJECTS AND INFRASTRUCTURE CONSULTANTS",
        main_role: "Structural Consultancy",
        color: "item-yellow",
    },
    {
        name: "Sanjoy Sanyal",
        companey: "BOUW CONSULTANTS PVT. LTD.",
        main_role: "Structural Consultancy",
        color: "item-blue",
    },
    {
        name: "Ar. Tilottama Lahiri",
        main_role: "Airport Architect",
        companey: "Ex. AAI G.M.",
        color: "item-black",
    },
    {
        name: "Prosun Chakroborty",
        companey: "POWERPRO ELECTRICAL DESIGN & CONSULTANCY SERVICES",
        main_role: "Electrical Consultancy",
        color: "item-blue",
    },
    {
        name: "Sudip Roy",
        companey: "WISEMEN SERVICES CONSULTANT",
        main_role: "PHE, HVAC & FIRE Consultancy",
        color: "item-black",
    },
    {
        name: "Joydeb Dey",
        companey: "SANJIVANI ENGINEERING CONSULTANTS",
        main_role: "PHE, HVAC & FIRE Consultancy",
        color: "item-yellow",
    },
    {
        name: "Manas Kanti Maji",
        companey: "",
        main_role: "Urban Designer",
        color: "item-yellow",
    },
    {
        name: "Abhinanda Chatterjee",
        companey: "",
        main_role: "Urban Designer",
        color: "item-blue",
    },
    {
        name: "Gupinath Bhandari",
        companey: "ENGINEERING CONSULTANCY SERVICES",
        main_role: "Infrastructural Consultancy",
        color: "item-yellow",
    },
];
