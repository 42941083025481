import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineMinus } from "react-icons/ai";

const FaqAccordion = ({ question, answer }) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <div className="faq">
            <div className="qes collapsed" data-toggle="collapse" data-target="#faq1" aria-expanded="false" aria-controls="collapseThree">
                <h5>{question}</h5>
                <div className="show-btn" onClick={() => setIsActive(!isActive)}>
                    <i>{isActive ? <AiOutlineMinus /> : <IoMdAdd />}</i>
                </div>
            </div>
            {isActive && (
                <div className="ans" id="faq1" aria-labelledby="headingThree" data-parent="#accordionExample">
                    <p>{answer}</p>
                </div>
            )}
        </div>
    );
};

export default FaqAccordion;
