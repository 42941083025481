import { MdHomeWork } from "react-icons/md";

export const CareerData = [
    {
        id: "career1",
        name: "ARCHITECTURE TEAM",
        icon: <MdHomeWork />,
    },
    {
        id: "career2",
        name: "STRUCTURAL TEAM",
        icon: <MdHomeWork />,
    },
    {
        id: "career3",
        name: "MEP TEAM",
        icon: <MdHomeWork />,
    },
    {
        id: "career4",
        name: "INTERIOR TEAM",
        icon: <MdHomeWork />,
    },
    {
        id: "career5",
        name: "ACCOUNTS TEAM",
        icon: <MdHomeWork />,
    },
    {
        id: "career6",
        name: "COMMUNICATION TEAM",
        icon: <MdHomeWork />,
    },
    {
        id: "career7",
        name: "ECOSYSTEM DEPARTMENT",
        icon: <MdHomeWork />,
    },
];
