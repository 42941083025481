import React, { useEffect, useState } from "react";
import { CLoader, FloatingSocial, Wrapper } from "../components";
import { Footer, Header } from "../layout";
import { Link } from "react-router-dom";

const Privicy = () => {
    const [Cloader, setCloader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setCloader(false);
        }, 1000);
    }, []);
    return (
        <Wrapper id="privicy" pageKey="vbridge__privicy__page">
            {/* header */}
            <Header />
            {/* header end */}
            {Cloader ? (
                <CLoader />
            ) : (
                <>
                    {/* news content */}
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9">
                                    <h2>Privicy Policy</h2>
                                    <p>
                                        Et sit et nullam at purus, eget. Fringilla massa neque scelerisque volutpat id viverra tincidunt. Quam elit
                                        velit quam lectus posuere egestas in. Molestie leo, sollicitudin turpis curabitur non. Sed gravida elementum
                                        nam cursus neque, amet mauris, enim. Malesuada nibh urna tempor.
                                    </p>
                                    <p>
                                        pEt ridiculus ullamcorper et donec dictum vel nulla aliquet massa. Id ultrices vel ut sed et nunc sapien non.
                                        Diam tellus semper nunc, placerat amet ac felis consequat. Nibh quis vel nisi felis justo. Nascetur convallis
                                        etiam sit et. Phasellus viverra tristique malesuada in egestas consequat. Ullamcorper interdum sapien, lacus
                                        curabitur malesuada in curabitur. Lectus vestibulum augue eros morbi neque, sociis ultrices et. Laoreet
                                        consequat massa at proin mi sed sed et. A nunc massa facilisi a enim neque sed ac. Diam morbi rhoncus dictum
                                        id praesent. Velit accumsan, ultrices aliquam turpis adipiscing.
                                    </p>
                                    <p>
                                        Nec auctor vitae augue leo ultrices fringilla integer. Velit venenatis lectus in quis mi. Ornare nam sed sed
                                        enim posuere ornare. Vestibulum laoreet aenean arcu ipsum. Hendrerit sed amet posuere ridiculus. Volutpat
                                        tellus blandit tortor consequat vestibulum eget pharetra. Condimentum diam at elit porttitor dolor iaculis ac.
                                        Diam ornare eleifend natoque tincidunt enim et. Lacinia natoque amet, ullamcorper tristique gravida lectus
                                        dictum. Arcu convallis eleifend consectetur pulvinar rhoncus augue feugiat suspendisse. Volutpat pellentesque
                                        massa, mattis egestas. Non pharetra eros ligula tellus, proin purus. Vitae iaculis eu amet, tellus fringilla
                                        in. In euismod ac, in sed. Et eleifend pharetra lectus dictumst eu.
                                    </p>
                                    <h5>
                                        Enim morbi nunc dictum quis vulputate tempor, interdum laoreet. Ipsum tristique sapien, nullam vitae tristique
                                        morbi amet vitae. Ipsum enim volutpat dictum pharetra nunc.
                                    </h5>
                                    <div className="letter-end">
                                        <p>Thank you</p>
                                        <h3>- Vbridge</h3>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="sidebar">
                                        <h3>Quick Links</h3>
                                        <Link to="/about">
                                            <h5>About Us</h5>
                                        </Link>

                                        <Link to="/contact">
                                            <h5>Contact</h5>
                                        </Link>
                                        <Link to="/news">
                                            <h5>News</h5>
                                        </Link>
                                        <Link to="/terms">
                                            <h5>Terms and Conditions</h5>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* news content end */}

                    {/* footer */}
                    <Footer />
                    {/* footer end */}
                </>
            )}
            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}
        </Wrapper>
    );
};

export default Privicy;
