import React, { useEffect, useState } from "react";
import { CLoader, FloatingSocial, Wrapper } from "../components";
import { Footer, Header } from "../layout";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

// news images
import { FadeSlide } from "../components/animation";
import { NACat, NewsData, NewsYear } from "../data/NewsData";
import { Link, useNavigate } from "react-router-dom";

const News = () => {
    let navigate = useNavigate();

    const [loading, setLoading] = useState(true);

    return (
        <Wrapper id="news" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}

            {loading && <CLoader />}
            <div id="new-projects" style={{ paddingTop: "50px" }}>
                <div className="container-fluid">
                    <div className="projects">
                        <Tabs>
                            <TabList className="project-tabs">
                                {NACat.map((c) => {
                                    return (
                                        <Tab>
                                            <p key={c.id}>{c.cat}</p>
                                        </Tab>
                                    );
                                })}
                            </TabList>
                            <div className="news news-page">
                                {/* news */}
                                <TabPanel>
                                    <div className="tab-content " id="pills-tabContent">
                                        <Tabs className="row">
                                            <div className="col-lg-2">
                                                <h5 className="text-subheading text-uppercase pl-4">All News</h5>
                                                <TabList className="time-line">
                                                    {/* <Tab style={{color:"#000"}}>All News</Tab> */}
                                                    {/* {NewsYear.map((y) => {
                                                        return <Tab key={y.id}>{y.year}</Tab>;
                                                    })} */}
                                                    {/* <div className="diamond"></div> */}
                                                </TabList>
                                            </div>
                                            <div className="col-lg-10" style={{ marginTop: "-50px" }}>
                                                {/* Latest NEWS */}
                                                <TabPanel>
                                                    <div className="row">
                                                        {NewsData.map((news, i) => {
                                                            return (
                                                                <div className="col-lg-4 item-padding" key={i}>
                                                                    <FadeSlide uqkey="news__quickUpdate__3" delayTime={0.7}>
                                                                        <div className="news-item" onClick={() => window.open(news.link, "_blank")}>
                                                                            <div className="news-img">
                                                                                <img onLoad={() => setLoading(false)} src={news.thumbnail} alt="" />
                                                                            </div>
                                                                            <div className="news-details-text">
                                                                                <div className="news-content py-2">
                                                                                    <h5 className="text-subheading text-uppercase">{`${news.title.substring(
                                                                                        0,
                                                                                        40
                                                                                    )}...`}</h5>
                                                                                    <p className="text-paragraph">{news.year}</p>
                                                                                </div>
                                                                                {/* <div className="news-author pt-0 pb-3">
                                                                                    <div className="avater">
                                                                                        <img
                                                                                            onLoad={() => setLoading(false)}
                                                                                            src={news.source}
                                                                                            alt=""
                                                                                        />
                                                                                    </div>
                                                                                    <div className="author">
                                                                                        <h5 className="text-subheading p-0">{news.SourceName}</h5>
                                                                                        <p className="role">{news.author}</p>
                                                                                    </div>
                                                                                </div> */}
                                                                            </div>
                                                                        </div>
                                                                    </FadeSlide>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </TabPanel>
                                                {/* Latest NEWS END */}

                                                {/* NEWS 2022 */}
                                                <TabPanel>
                                                    <div className="row">
                                                        {NewsData.filter((y) => y.year === "2022").map((news, i) => {
                                                            return (
                                                                <div className="col-lg-3 " key={i}>
                                                                    <FadeSlide uqkey="news__quickUpdate__3" delayTime={0.7}>
                                                                        <div className="news-item" onClick={() => window.open(news.link, "_blank")}>
                                                                            <div className="news-img">
                                                                                <img onLoad={() => setLoading(false)} src={news.thumbnail} alt="" />
                                                                            </div>
                                                                            <div className="news-content py-2">
                                                                                {/* <h5>{news.title}</h5>
                                                                                <p>{news.description}</p> */}
                                                                                <h5>{`${news.title.substring(0, 50)}...`}</h5>
                                                                                <p>{`${news.description.substring(0, 100)}...`}</p>
                                                                            </div>
                                                                            <div className="news-author pt-0 pb-3">
                                                                                <div className="avater">
                                                                                    <img onLoad={() => setLoading(false)} src={news.source} alt="" />
                                                                                </div>
                                                                                <div className="author">
                                                                                    <h5>{news.SourceName}</h5>
                                                                                    <p className="role">{news.author}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </FadeSlide>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </TabPanel>
                                                {/* NEWS 2022 END */}

                                                {/* NEWS 2021 */}
                                                <TabPanel>
                                                    <div className="row">
                                                        {NewsData.filter((y) => y.year === "2021").map((news, i) => {
                                                            return (
                                                                <div className="col-lg-3 " key={i}>
                                                                    <FadeSlide uqkey="news__quickUpdate__3" delayTime={0.7}>
                                                                        <div className="news-item" onClick={() => window.open(news.link, "_blank")}>
                                                                            <div className="news-img">
                                                                                <img onLoad={() => setLoading(false)} src={news.thumbnail} alt="" />
                                                                            </div>
                                                                            <div className="news-content py-2">
                                                                                {/* <h5>{news.title}</h5>
                                                                                <p>{news.description}</p> */}
                                                                                <h5>{`${news.title.substring(0, 50)}...`}</h5>
                                                                                <p>{`${news.description.substring(0, 100)}...`}</p>
                                                                            </div>
                                                                            <div className="news-author pt-0 pb-3">
                                                                                <div className="avater">
                                                                                    <img onLoad={() => setLoading(false)} src={news.source} alt="" />
                                                                                </div>
                                                                                <div className="author">
                                                                                    <h5>{news.SourceName}</h5>
                                                                                    <p className="role">{news.author}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </FadeSlide>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </TabPanel>
                                                {/* NEWS 2021 END */}

                                                {/* NEWS 2020 */}
                                                <TabPanel>
                                                    <div className="row">
                                                        {NewsData.filter((y) => y.year === "2020").map((news, i) => {
                                                            return (
                                                                <div className="col-lg-3 " style={{ marginTop: "0px" }} key={i}>
                                                                    <FadeSlide uqkey="news__quickUpdate__3" delayTime={0.7}>
                                                                        <div className="news-item" onClick={() => window.open(news.link, "_blank")}>
                                                                            <div className="news-img">
                                                                                <img onLoad={() => setLoading(false)} src={news.thumbnail} alt="" />
                                                                            </div>
                                                                            <div className="news-content py-2">
                                                                                {/* <h5>{news.title}</h5>
                                                                                <p>{news.description}</p> */}
                                                                                <h5>{`${news.title.substring(0, 50)}...`}</h5>
                                                                                <p>{`${news.description.substring(0, 100)}...`}</p>
                                                                            </div>
                                                                            <div className="news-author pt-0 pb-3">
                                                                                <div className="avater">
                                                                                    <img onLoad={() => setLoading(false)} src={news.source} alt="" />
                                                                                </div>
                                                                                <div className="author">
                                                                                    <h5>{news.SourceName}</h5>
                                                                                    <p className="role">{news.author}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </FadeSlide>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </TabPanel>
                                                {/* NEWS 2020 END */}
                                                {/* NEWS 2019 */}
                                                <TabPanel>
                                                    <div className="row">
                                                        {NewsData.filter((y) => y.year === "2019").map((news, i) => {
                                                            return (
                                                                <div className="col-lg-3 " key={i}>
                                                                    <FadeSlide uqkey="news__quickUpdate__3" delayTime={0.7}>
                                                                        <div className="news-item" onClick={() => window.open(news.link, "_blank")}>
                                                                            <div className="news-img">
                                                                                <img onLoad={() => setLoading(false)} src={news.thumbnail} alt="" />
                                                                            </div>
                                                                            <div className="news-content py-2">
                                                                                {/* <h5>{news.title}</h5>
                                                                                <p>{news.description}</p> */}
                                                                                <h5>{`${news.title.substring(0, 50)}...`}</h5>
                                                                                <p>{`${news.description.substring(0, 100)}...`}</p>
                                                                            </div>
                                                                            <div className="news-author pt-0 pb-3">
                                                                                <div className="avater">
                                                                                    <img onLoad={() => setLoading(false)} src={news.source} alt="" />
                                                                                </div>
                                                                                <div className="author">
                                                                                    <h5>{news.SourceName}</h5>
                                                                                    <p className="role">{news.author}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </FadeSlide>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </TabPanel>
                                                {/* NEWS 2019 END */}
                                            </div>
                                        </Tabs>
                                    </div>
                                </TabPanel>
                                {/* news end */}
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>
            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default News;
