import React from "react";
import { motion } from "framer-motion";

const Fade = ({ children, uqkey }) => {
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} key={uqkey}>
            {children}
        </motion.div>
    );
};

export default Fade;
