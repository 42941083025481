export const JourneyData = [
    {
        id: "j1",
        name: "OFFICIAL STUDIO OF VBRIDGE PARTNERS STARTED IN KOLKATA",
        date: "Aug-19",
        desc: "WITH A SINGLE TABLE  TWO CHAIRS",
        color: "3d3d3d",
    },
    {
        id: "j2",
        name: "RESTARTED JAUGRAM COMMUNITY PROJECT",
        date: "Oct-19",
        desc: "TWO COFOUNDERS  FEW ARTISTS",
        color: "5a98d1",
    },
    {
        id: "j3",
        name: "LANDED FIRST BIG CONTRACT WITH JIS GROUP",
        date: "Dec-19",
        desc: "FIRST STABILIZATION OF THE FINANCIAL POSITION",
        color: "ffcb0c",
    },
    {
        id: "j4",
        name: "HIRED THE FIRST OFFICIAL TEAM MEMBER BEYOND PARTNERS",
        date: "Jan-20",
        desc: "FIRST PERMANENT MEMBER ON PAYROLL",
        color: "3d3d3d",
    },
    {
        id: "j5",
        name: "CELEBRATED FIRST EXPANSION OF OFFICE SPACE",
        date: "Mar-20",
        desc: "A GARAGE SPACE IN THE CBD WITH 4 SEATER SPACE",
        color: "5a98d1",
    },
    {
        id: "j6",
        name: "COVID PANDEMIC OFFICIALLY BEGUN",
        date: "Mar-20",
        desc: "PREVIOUS CONTRACTS GOT ON HOLD BUT NO EMPLOYEE WAS DROPPED",
        color: "ffcb0c",
    },
    {
        id: "j7",
        name: "RECEIVED FIRST MAJOR CORPORATE OFFER FROM TATA STEEL",
        date: "Jun-20",
        desc: "CHALLENGE WAS TO CONVERT THIS OFFER",
        color: "3d3d3d",
    },
    {
        id: "j8",
        name: "INCUBATION OF VBRIDGE ENGINEERING  PROJECTS PVT LTD(VEPPL) BEGUN",
        date: "Jul-20",
        desc: "UNOFFICIALLY THE TEAM STARTED WORKING ON THE MAJOR GOAL",
        color: "5a98d1",
    },
    {
        id: "j9",
        name: "CONVERTED THE TATA STEEL OFFER THROUGH PERFOMANCE",
        date: "Aug-20",
        desc: "OFFER CONVERTED THROUGH TEAM WORK",
        color: "ffcb0c",
    },
    {
        id: "j10",
        name: "SECOND SET OF MAJOR RECRUITMENT TOOK PLACE",
        date: "Sep-20",
        desc: "CROSSED THE 05 RECRUITMENT MARK BEYOND FOUNDERS",
        color: "3d3d3d",
    },
    {
        id: "j11",
        name: "GARAGE OFFICE SPACE TO FIRST CORPORATE OFFICE SPACE",
        date: "Oct-20",
        desc: "INTO AN 08 SEATER OFFICE SPACE",
        color: "5a98d1",
    },
    {
        id: "j12",
        name: "RECEIVED AN INTERNATIONAL PROJECT OFFER FROM BHUTAN",
        date: "Nov-20",
        desc: "FIRST MAJOR INTERNATIONAL PROJECT OFFER",
        color: "ffcb0c",
    },
    {
        id: "j13",
        name: "CONVERTED THE PARO INTERNATIONAL PROJECT WORK",
        date: "Dec-20",
        desc: "BIGGEST PROJECT SCALE OFFER CONVERTED",
        color: "3d3d3d",
    },
    {
        id: "j14",
        name: "COMPLETED 2,00,000 SQFT+ WITH TATA STEEL WITHIN 07 MONTHS",
        date: "Mar-21",
        desc: "RECEIVED EXTENSION OF OFFER",
        color: "5a98d1",
    },
    {
        id: "j15",
        name: "RECEIVED FIRST MAJOR GOVERNMENT PROJECT OFFER IN INDIA",
        date: "May-21",
        desc: "TEAM EXPANSION PROCEDURE STARTED",
        color: "ffcb0c",
    },
    {
        id: "j16",
        name: "GOVERNMENT PROJECT OFFER FROM NDITA CONVERTED ",
        date: "Jul-21",
        desc: "INCUBATION OF VEPPL STARTED",
        color: "3d3d3d",
    },
    {
        id: "j17",
        name: "RECEIVED AWARD  RECOGNITION AT THE LONDON DESIGN BIENNALE",
        date: "Jul-21",
        desc: "FIRST MAJOR INTERNATIONAL RECOGNITION TO THE INCUBATED VEPPL",
        color: "5a98d1",
    },
    {
        id: "j18",
        name: "THIRD MAJOR RECRUITEMENT  OFFICE HEADQUARTER",
        date: "Sep-21",
        desc: "OFFICIAL CONVERSION FROM INCUBATION TO STARTUP OF VBRIDGE ENGINEERING  PROJECTS PVT LTD",
        color: "ffcb0c",
    },
    {
        id: "j19",
        name: "TEAM REACHED A 10 MEMBER MARK  05 ECOSYSTEM PARTNERS",
        date: "Dec-21",
        desc: "NEW OFFICE SPACE WAS ALSO EXPANDED",
        color: "3d3d3d",
    },
    {
        id: "j20",
        name: "INTERNAL AUDIT REVEALED 100+ PROJECTS COMPLETED BY THE TEAM ",
        date: "Feb-22",
        desc: "ALSO RECEIVED EXPANSION OFFER TO MUMBAI, DUBAI  KUALA LUMPUR",
        color: "5a98d1",
    },
    {
        id: "j21",
        name: "MULTICRORE TURNOVER REVEALED FROM INTERNAL AUDIT FOR THE FIRST TIME",
        date: "Mar-22",
        desc: "MILESTONE AS A BOOTSTRAP CONSULTANCY FIRM IN SERVICE BASED INDUSTRY",
        color: "ffcb0c",
    },
    {
        id: "j22",
        name: "INTERNAL AUDIT REVEALED 20+ NATIONAL  INTERNATIONAL RECOGNITIONS",
        date: "Apr-22",
        desc: "ANOTHER MILESTONE FOR A YOUNG ARCHITECTURE FIRM",
        color: "3d3d3d",
    },
    {
        id: "j23",
        name: "DESIGNED 50+ COVID HOSPITALS WITH TATA STEEL NESTIN ",
        date: "Jun-22",
        desc: "WAIVED FEE AS A COMMUNITY SOCIAL RESPONSIBILITY",
        color: "5a98d1",
    },
    {
        id: "j24",
        name: "MAJOR CITY LEVEL PROJECT OF THE ILLUSION ART COMPLETED IN KOLKATA",
        date: "Aug-22",
        desc: "RECEIVED NATIONAL LEVEL RECOGNITIONS AGAIN",
        color: "ffcb0c",
    },
    {
        id: "j25",
        name: "OFFICIALLY ENTERS THE MULTIMILLIONAIRE CLUB AS A STARTUP WITHIN FY 21-22",
        date: "Sep-22",
        desc: "MILESTONE VALUATION OF INR 21.99 CRORE VALUATION AS A YOUNG BOOTSTRAP STARTUP",
        color: "3d3d3d",
    },
    {
        id: "j26",
        name: "CELEBRATED VIVACE TO BRING EXHIBITION OF ARCHITECTURAL PROJECTS OF VBRIDGE",
        date: "Sep-22",
        desc: "CONCLAVE  EXHIBITION WITH STALWARTS OF THE INDUSTRY",
        color: "5a98d1",
    },
    {
        id: "j27",
        name: "REACHED THE 15 MEMBER TEAM MARK ALONG WITH 10 MAJOR ECOSYSTEM PARTNERS",
        date: "Oct-22",
        desc: "RECRUITMENT IN LARGE SCALE ALONG WITH ANOTHER NEW STUDIO SPACE IN KOLKATA",
        color: "ffcb0c",
    },
    {
        id: "j28",
        name: "EXPANDED IN MUMBAI  KUALA LUMPUR ",
        date: "Oct-22",
        desc: "INFRASTRUCTURE EXPANSION WITH ONGOING RECRUITMENTS",
        color: "3d3d3d",
    },
];
