import React, { useEffect, useState } from "react";

// -------------------------------------------
// all css/scss files
// -------------------------------------------
// bootstrap scss
import "bootstrap/dist/css/bootstrap.css";
// react tabs
import "react-tabs/style/react-tabs.css";
// custom scss
import "./assets/scss/style.scss";

// -------------------------------------------

// -------------------------------------------
// custom page routes
// -------------------------------------------
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./router/Routing";
import Loader from "./components/base/loader/Loader";
// -------------------------------------------

import { GetSectionIndex } from "./helpers/context/ContextApi";

const App = () => {
    const [sectionIndex, setSectionIndex] = useState(0);
    const [loading, setLoading] = useState(true);

    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    });

    return (
        <div className="outer">
            {loading && <Loader />}
            <GetSectionIndex.Provider value={{ sectionIndex, setSectionIndex }}>
                <Router>
                    <Routing />
                </Router>
            </GetSectionIndex.Provider>

            {/* {loading ? (
                <Loader />
            ) : (
                <GetSectionIndex.Provider value={{ sectionIndex, setSectionIndex }}>
                    <Router>
                        <Routing />
                    </Router>
                </GetSectionIndex.Provider>
            )} */}
        </div>
    );
};

export default App;
