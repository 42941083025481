export const AwardsData = [
    {
        id: "award6",
        title: "MAKE CALCUTTA RELEVANT AGAIN-CCU",
        year: "2022",
        place: "MAKING TODAY AWARD",
        info: "",
        org: "ILLUSION ART PROJECT",
    },
    {
        id: "award1",
        title: "LONDON DESIGN BIENNALE",
        year: "2021",
        place: "BIOPHILLIC & SUSTAINABLE ARCHITECTURE CATEGORY",
        info: "ONE OF THE YOUNGEST ARCHITECTS TO REPRESENT INDIA & RECEIVE A RECOGNITION AT THE BIENNALE",
        org: "JAUGRAM",
    },
    {
        id: "award7",
        title: "UCL BARLETT",
        year: "2021",
        place: "PUBLICATION MENTION",
        info: "",
        org: "STREET ART",
    },
    {
        id: "award2",
        title: "THE NEXT ARCHITECT AWARD",
        year: "2020",
        place: "ARCHITECTURE FOR THE COMMUNITY",
        info: "UNIQUE RELATABLE LINES THAT AFFECTED SEVERAL LIVES DURING COVID THROUGH SOCIAL DISTANCING",
        org: "STREET ART",
    },
    {
        id: "award3",
        title: "WORLD ARCHITECTURE COMMUNITY",
        year: "2020",
        place: "SUSTAINABLE VILLAGE DEVELOPMENT PROJECT",
        info: "AFFECTED THOUSANDS OF RURAL LIFE THROUGH THIS PROJECT",
        org: "JAUGRAM",
    },
    {
        id: "award4",
        title: "WORLD ARCHITECTURE COMMUNITY",
        year: "2020",
        place: "RURAL DEVELOPMENT & DESIGN",
        info: "IMMENSELY HELPED TO SPREAD AWARENESS DURING COVID",
        org: "STREET ART",
    },
    {
        id: "award5",
        title: "TATA EDUCATIONAL EXCELLENCE PROGRAMME",
        year: "2020",
        place: "YOUTH ORIENTATION & DEVELOPMENT PROGRAM",
        info: "",
        org: "SPEAKERS",
    },

    {
        id: "award8",
        title: "INTERNATIONAL FUTURISTIC RESIDENCE 2050",
        year: "2019",
        place: "TOP 10 WINNER FOR UNIQUE RESIDENCE DESIGN",
        info: "",
        org: "",
    },
    {
        id: "award9",
        title: "JOSH TALKS",
        year: "2019",
        place: "MOTIVATING YOUTH TOWARDS ARCHITECTURE",
        info: "",
        org: "SPEAKERS",
    },
];
