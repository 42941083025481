import React from "react";
import { HomeAwardsData } from "../../../data/HomeData";
import { Award } from "../../../components";
import { Link } from "react-router-dom";
import { AwardsData } from "../../../data/AwardsData";

const HomeAwards = () => {
    return (
        <div className="container pt-5">
            <h2 className="pb-3 mt-5 text-display text-center">Awards and Recognition</h2>
            <div className="awards-wrapper">
                <div className="row">
                    {AwardsData.map((ad) => (
                        <div className="col-lg-6" key={ad.id}>
                            <Award year={ad.year} title={ad.title} place={ad.place} info={ad.info} org={ad.org} />
                        </div>
                    ))}
                </div>
                {/* <div className="text-center">
                    <Link to="/awards" className="more-btn">
                        SHOW MORE
                    </Link>
                </div> */}
            </div>
        </div>
    );
};

export default HomeAwards;
