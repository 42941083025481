import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FloatingSocial, Sponsor, TimeLine, Wrapper } from "../components";
import { Footer, Header } from "../layout";

const Clients = () => {
    const [loading, setLoading] = useState(true);

    return (
        <Wrapper id="clients" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}

            <h2 className="text-center text-display">Clients, Partners & Collaborations</h2>
            <Sponsor />
            <div className="text-center">
                <Link to="/testimonial" className="btn-primary p-3 bg-dark text-uppercase text-subheading">
                    See Our Testimonials
                </Link>
            </div>

            {/* awards timeline */}
            {/* <TimeLine /> */}
            {/* awards timeline end */}
            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Clients;
