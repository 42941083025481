import React, { useState } from "react";
import { CLoader, FloatingSocial, ProjectView, Wrapper } from "../components";
import { Footer, Header } from "../layout";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AnimatePresence } from "framer-motion";
import { ProjectsCategories, ProjectsData, ProjectStatus, ProjectYears } from "../data/ProjectsData";
import { GalleryData } from "../data/GalleryData";

const Gallery = () => {
    const tabSettings = {
        defaultIndex: 0,
        forceRenderTabPanel: true,
    };

    const [showFullView, setShowFullView] = useState(false);
    const [currentFullViewProjectImg, setCurrentFullViewProjectImg] = useState([]);

    const viewProject = (e) => {
        // current project id
        const currentProjectId = e.target.attributes.getNamedItem("data-id").value;
        // console.log(currentProjectId);

        // filter id
        ProjectsData.filter((d) => d.id === currentProjectId).forEach((d) => {
            let images = [];
            // console.log(d.full_images);
            for (let i = 0; i < d.full_images.length; i++) {
                images.push(d.full_images[i]);
            }
            // console.log(images);
            setCurrentFullViewProjectImg(images);
        });
        setShowFullView(true);
    };
    const [loading, setLoading] = useState(true);
    return (
        <Wrapper id="gallery" pageKey="vbridge__new__projects__page">
            {/* header */}
            <Header />
            {/* header end */}
            {loading && <CLoader />}

            <div className="container">
                <h1 className="text-display text-center pb-5 text-uppercase">gallery</h1>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="all-projects">
                            <div className="row">
                                {/* <!-- all photos --> */}
                                {GalleryData.map((d, i) => (
                                    <div className="col-lg-4 item-padding">
                                        <div className="project p-0 m-0" key={d.id}>
                                            <div className="pro-img">
                                                <img onLoad={() => setLoading(false)} src={d.img} alt="" />
                                            </div>
                                            <div className="info">
                                                <h5 className="text-heading">{d.name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* <!-- all photos end --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Gallery;
