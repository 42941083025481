// -------------------------------------------
// Social  data
// -------------------------------------------
import {
    sw_thumbnail_1,
    sw_thumbnail_2,
    sw_thumbnail_4,
    community__THE_ART_STREET,
    community__there_for_you,
    community__india_to_world,
    community__JAUGRAM,
    community__SAVE_THE_PLANET,
    social_works__MIRR,
    social_works__probano_career_counselling,
    social_works__Football,
} from "../helpers/Images";

export const Community_Data = [
    {
        id: "sp1",
        name: "ENTHUSIASM OVERLOAD",
        thumbnail: sw_thumbnail_1,
        link: "https://www.facebook.com/photo/?fbid=522092963252181&set=a.522092969918847",
    },
    {
        id: "sp2",
        name: "NEXTGEN INITIATIVES",
        thumbnail: sw_thumbnail_2,
        link: "https://www.facebook.com/abridge2k17/photos/a.756504001439474/1442019759554558/",
    },
    {
        id: "sp3",
        name: "INTERNATIONAL RECOGNITION FOR A COMMUNITY WORK",
        thumbnail: community__india_to_world,
        link: "https://www.facebook.com/photo/?fbid=1440207566402444&set=a.756504001439474",
    },
    {
        id: "sp4",
        name: "TRUST THE TEAM SPIRIT",
        thumbnail: sw_thumbnail_4,
        link: "https://www.facebook.com/media/set/?vanity=abridge2k17&set=a.529045425890268",
    },
    {
        id: "sp5",
        name: "WE ARE THERE FOR YOU",
        thumbnail: community__there_for_you,
        link: "https://www.facebook.com/photo?fbid=1432284147194786&set=pcb.1432327563857111",
    },
    {
        id: "sp6",
        name: "LETS SAVE THE PLANET",
        thumbnail: community__SAVE_THE_PLANET,
        link: "https://www.facebook.com/photo/?fbid=1427369207686280&set=pcb.1427376621018872",
    },
    {
        id: "sp7",
        name: "JAUGRAM - THE RIPPLE EFFECT OF BIOPHILLIC ARCHITECTURE",
        thumbnail: community__JAUGRAM,
        link: "https://www.facebook.com/abridge2k17/photos/a.756504001439474/1430713744018493/",
    },
    {
        id: "sp8",
        name: "STREET ART - THE COMMUNITY EFFECT THROUGH DESIGN",
        thumbnail: community__THE_ART_STREET,
        link: "https://www.youtube.com/watch?v=5UEF8pdhJLo",
    },
    {
        id: "sp9",
        name: "PROBANO CAREER COUNSELLING FOR YOUTH",
        thumbnail: social_works__probano_career_counselling,
        link: "https://www.facebook.com/watch/?v=251757949355935&ref=sharing",
    },
    {
        id: "sp10",
        name: "SUPPORT SPORTS & FITNESS",
        thumbnail: social_works__Football,
        link: "https://www.facebook.com/abridge2k17/photos/a.699278643828677/699278893828652/?type=3&app=fbl",
    },
    {
        id: "sp11",
        name: "DIFFERENT ABLED ARE TRULY SPECIAL",
        thumbnail: social_works__MIRR,
        link: "https://www.facebook.com/abridge2k17/photos/a.589431924813350/589432024813340/?type=3&app=fbl",
    },
];
