import React from "react";
import { Wrapper, Testimonials, FloatingSocial } from "../components";
import { ClientsData } from "../data/ClientsData";
import { Footer, Header } from "../layout";

const Testimonial = () => {
    return (
        <Wrapper id="testimonial" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}
            <div className="container-fluid">
            	<h2 className="pb-3 text-display text-center">Testimonials</h2>
                <div className="row">
                    {/* testimonials */}
                    <div className="col-lg-12">
                        <Testimonials />
                    </div>
                </div>
            </div>

            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Testimonial;
