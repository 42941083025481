import React from "react";

const Service = ({ name, icon }) => {
    return (
        <div className="service-wrapper">
            <div className="service">
                <i>{icon}</i>
                <h5 className="text-subheading text-uppercase pt-3">{name}</h5>
            </div>
        </div>
    );
};

export default Service;
