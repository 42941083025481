export const SustainData = [
    {
        id: "sus1",
        sustainPercentage: "Green Designs",
        sustainInfo:
            "Out of the total area designed by VBridge across multiple projects, at least 18% has been reserved for propagating green initiatives like gardens, roof top plants, parks, etc.",
    },
    {
        id: "sus2",
        sustainPercentage: "Higher Energy Savings",
        sustainInfo: "18% Quarterly Reduction in Energy Consumption for FY-22.",
    },
    {
        id: "sus3",
        sustainPercentage: "Lesser Fuel Consumption",
        sustainInfo: "13% Quarterly Reduction in Fuel Consumption in FY-22.",
    },
    {
        id: "sus4",
        sustainPercentage: "Journey Towards Net Zero",
        sustainInfo: "22% Quarterly Reduction in Net Zero Emissions in FY-22",
    },
    {
        id: "sus5",
        sustainPercentage: "Afforestation",
        sustainInfo:
            "Carbon Offsetting has been taken up as a mitigative measure by VBridge from FY-2022, to address the role of young startups towards the challenges of Climate Change that India faces. ",
    },
    {
        id: "sus6",
        sustainPercentage: "Offset As We Grow",
        sustainInfo: "3+ Afforestation Initiatives in FY-22 with an average monthly offsetting of 24.58 kg of CO2.",
    },
    {
        id: "sus7",
        sustainPercentage: "The VBridge Net Zero Plan",
        sustainInfo:
            "VBridge aims at becoming a Net Zero Organisation with minimal carbon footprint and is set to draw the VBridge Net Zero Goals, later this year.",
    },
];
