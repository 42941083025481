import {
    // thumbnails
    gallery__1,
    gallery__2,
    gallery__3,
    gallery__4,
    gallery__5,
    gallery__6,
    gallery__7,
    gallery__8,
    gallery__9,
    gallery__10,
    gallery__11,
    gallery__12,
    gallery__13,
    gallery__14,
    gallery__15,
    gallery__16,
    gallery__17,
    gallery__18,
    gallery__19,
    gallery__20,
    gallery__21,
    gallery__22,
    gallery__23,
    gallery__24,
    gallery__25,
    gallery__26,
    gallery__27,
    gallery__28,
    gallery__29,
    gallery__30,
    gallery__31,
    gallery__32,
    gallery__33,
    gallery__34,
    gallery__35,
    gallery__36,
} from "../helpers/Images";

export const GalleryData = [
    {
        name: "START OF VBRIDGE",
        img: gallery__1,
    },
    {
        name: "GARAGE OFFICE OF VBRIDGE",
        img: gallery__2,
    },
    {
        name: "FIRST OFFICE OPENING BY DR. AJOY RAY (PADMASHREE)",
        img: gallery__3,
    },
    {
        name: "NEW OFFICE OPENING PUJO",
        img: gallery__4,
    },
    {
        name: "FAMILY GATHERING",
        img: gallery__5,
    },
    {
        name: "OFFICE GATHERING",
        img: gallery__6,
    },
    {
        name: "EARLY STARTUP DAYS",
        img: gallery__7,
    },
    {
        name: "VBRIDGE IN VIVACE",
        img: gallery__8,
    },
    {
        name: "SAFETY OREINTATION PROGRAM AT OFFICE",
        img: gallery__9,
    },
    {
        name: "PLANTATION DRIVE FROM AT OFFICE",
        img: gallery__10,
    },
    {
        name: "SARASWATI PUJO PARTY AT OFFICE",
        img: gallery__11,
    },
    {
        name: "HOLI CELEBRATION WITH TEAM",
        img: gallery__12,
    },
    {
        name: "MILLION DOLLAR CLUB CELEBRATION",
        img: gallery__13,
    },
    {
        name: "WITH IAS DEBASHIS SEN AT INAUGRATION OF VBRIDGE NEW LOGO",
        img: gallery__14,
    },
    {
        name: "INAUGRATION OF EXHIBITION BY MR. HARSHVARDHAN NEOTIA (PADMASHREE)",
        img: gallery__15,
    },
    {
        name: "WORKING IN A VILLAGE",
        img: gallery__16,
    },
    {
        name: "GREEN APPROACHED WITHIN OFFICE",
        img: gallery__17,
    },
    {
        name: "ILLUSTRATION WITHIN A VILLAGE SCHOOL",
        img: gallery__18,
    },
    {
        name: "DIRECTORS WITH MR. BHAICHUNG BHUTIA",
        img: gallery__19,
    },
    {
        name: "STUDENTS WORKSHOP",
        img: gallery__20,
    },
    {
        name: "THE FIRST BIG PITCH",
        img: gallery__21,
    },
    {
        name: "FIRST NAMEPLATE OF VBRIDGE",
        img: gallery__22,
    },
    {
        name: "SPEAKER &amp; YOUTH INCUBATION",
        img: gallery__23,
    },
    {
        name: "DIRECTORS IN STUDENT LIFE",
        img: gallery__24,
    },
    {
        name: "FAMILY &amp; SUPPORT",
        img: gallery__25,
    },
    {
        name: "SPORTS &amp; SUPPORT",
        img: gallery__26,
    },
    {
        name: "A SPECIAL DAY WITH THE DIFFERENTLY ABLED",
        img: gallery__27,
    },
    {
        name: "SPEAKERS IN BHUTAN",
        img: gallery__28,
    },
    {
        name: "DIRECTORS WITH STUDENT MADE PORTRAITS",
        img: gallery__29,
    },
    {
        name: "DIRECTORS IN BHUTAN",
        img: gallery__30,
    },
    {
        name: "TEAM IN SINGAPORE",
        img: gallery__31,
    },
    {
        name: "MALAYSIA HARD ROCK CAFÉ",
        img: gallery__32,
    },
    {
        name: "LEARNING TRIP TO DUBAI EXPO",
        img: gallery__33,
    },
    {
        name: "DURGA PUJA &amp; TEAM",
        img: gallery__34,
    },
    {
        name: "SITE VISIT IN JAMSHEDPUR",
        img: gallery__35,
    },
    {
        name: "OFFICE PICNIC",
        img: gallery__36,
    },
];
