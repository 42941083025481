import {
    // full images
    project__full_BPCL_IN_OUT_STORE,
    project__full_CREMATORIUM,
    project__full_ILLUSION_ART_RAILING,
    project__full_JAQUAR_RETAIL_STORE,
    project__full_MOHALLA_CLINIC,
    project__full_MTMC_LIBRARY,
    project__full_NARULA_INSTITUTE_CAFETERIA,
    project__full_NDITA_ICONIC_GATE,

    // project__full_TSJ_COKE_PLANT,
    project__full_TSJ_COKE_PLANT_1,
    project__full_TSJ_COKE_PLANT_2,
    project__full_TSJ_COKE_PLANT_3,
    project__full_TSJ_COKE_PLANT_4,
    project__full_VEDANTA_TOWNSHIP,

    //project__full MILI APARTMENT
    project_full_MILI_APARTMENT_1,
    project_full_MILI_APARTMENT_3,
    project_full_MILI_APARTMENT_4,

    //project_full JOYVILLE APARTMENT
    project_full_JOYVILLE_APARTMENT_1,
    project_full_JOYVILLE_APARTMENT_2,
    project_full_JOYVILLE_APARTMENT_3,
    project_full_JOYVILLE_APARTMENT_4,

    //project full PADMALAYA
    project_full_PADMALYA_1,
    project_full_PADMALYA_2,
    project_full_PADMALYA_3,
    project_full_PADMALYA_4,
    project_full_PADMALYA_5,

    //project full BOHATA_3BHK
    project_full_BOHATA_3BHK_1,
    project_full_BOHATA_3BHK_2,
    project_full_BOHATA_3BHK_3,
    project_full_BOHATA_3BHK_4,

    //project full SRIJAN SOUTHWIND
    project_full_SRIJAN_SOUTHWIND_1,
    project_full_SRIJAN_SOUTHWIND_2,
    project_full_SRIJAN_SOUTHWIND_3,

    //project full BOHATA_6BHK
    project_full_BOHATA_6BHK_1,
    project_full_BOHATA_6BHK_2,
    project_full_BOHATA_6BHK_3,
    project_full_BOHATA_6BHK_4,
    project_full_BOHATA_6BHK_5,
    project_full_BOHATA_6BHK_6,

    //project full ARUN KRIPAL RESIDENCE
    project_full_ARUN_KRIPAL_RESIDENCE_1,
    project_full_ARUN_KRIPAL_RESIDENCE_2,
    project_full_ARUN_KRIPAL_RESIDENCE_3,

    //project BPCL IN AND OUT
    project__full_BPCL_IN_OUT_1,

    //project CIIIT MANIPUR
    project_full_CIIIT_MANIPUR_1,
    project_full_CIIIT_MANIPUR_2,

    //project full commentry box sca
    project_full_project_thumb_COMMENTRY_BOX_SCA_1,
    project_full_project_thumb_COMMENTRY_BOX_SCA_2,

    //project full covid hospital
    project__full_COVID_HOSPITAL_1,
    project__full_COVID_HOSPITAL_2,
    project__full_COVID_HOSPITAL_3,
    project__full_COVID_HOSPITAL_4,

    //project full CRC TARAPUR
    project_full_CRC_TARAPUR_1,
    project_full_CRC_TARAPUR_2,
    project_full_CRC_TARAPUR_3,

    //project full CRECHE FACILITY
    project_full_CRECHE_FACILITY_1,
    project_full_CRECHE_FACILITY_2,
    project_full_CRECHE_FACILITY_3,
    project_full_CRECHE_FACILITY_4,

    //project full DUPLEX DHANBAD
    project_full__DUPLEX_DHANBAD_1,
    project_full__DUPLEX_DHANBAD_2,
    project_full__DUPLEX_DHANBAD_3,

    //project full GM QSEB
    project_full_GM_QSEB_1,
    project_full_GM_QSEB_2,
    project_full_GM_QSEB_3,

    //project full HIGHRISE JAMSHEDPUR
    project_full_HIGHRISE_JAMSHEDPUR_1,
    project_full_HIGHRISE_JAMSHEDPUR_2,
    project_full_HIGHRISE_JAMSHEDPUR_3,
    project_full_HIGHRISE_JAMSHEDPUR_4,
    project_full_HIGHRISE_JAMSHEDPUR_5,
    project_full_HIGHRISE_JAMSHEDPUR_6,
    project_full_HIGHRISE_JAMSHEDPUR_7,
    project_full_HIGHRISE_JAMSHEDPUR_8,
    project_full_HIGHRISE_JAMSHEDPUR_9,
    project_full_HIGHRISE_JAMSHEDPUR_10,
    project_full_HIGHRISE_JAMSHEDPUR_11,
    project_full_HIGHRISE_JAMSHEDPUR_12,
    project_full_HIGHRISE_JAMSHEDPUR_13,
    project_full_HIGHRISE_JAMSHEDPUR_14,
    project_full_HIGHRISE_JAMSHEDPUR_15,
    project_full_HIGHRISE_JAMSHEDPUR_16,
    project_full_HIGHRISE_JAMSHEDPUR_17,
    project_full_HIGHRISE_JAMSHEDPUR_18,
    project_full_HIGHRISE_JAMSHEDPUR_19,
    project_full_HIGHRISE_JAMSHEDPUR_20,

    // project full NOAMUNDI HOSPITAL
    project_full_NOAMUNDI_HOSPITAL_1,
    project_full_NOAMUNDI_HOSPITAL_2,
    project_full_NOAMUNDI_HOSPITAL_3,

    // project full JRD TATA GATE
    project_full_JRD_TATA_GATE_1,
    project_full_JRD_TATA_GATE_2,
    project_full_JRD_TATA_GATE_3,
    project_full_JRD_TATA_GATE_4,
    project_full_JRD_TATA_GATE_5,
    project_full_JRD_TATA_GATE_6,

    // project full MAHE
    project_full_MAHE_1,
    project_full_MAHE_2,
    project_full_MAHE_3,
    project_full_MAHE_4,
    project_full_MAHE_5,
    project_full_MAHE_6,
    project_full_MAHE_7,

    //project full NEELKAMAL
    project_full_NEELKAMAL_1,
    project_full_NEELKAMAL_2,
    project_full_NEELKAMAL_3,

    //project full NIT Cafeteria
    project_full_NIT_CAFETERIA_1,
    project_full_NIT_CAFETERIA_2,
    project_full_NIT_CAFETERIA_3,
    project_full_NIT_CAFETERIA_4,
    project_full_NIT_CAFETERIA_5,
    project_full_NIT_CAFETERIA_6,
    project_full_NIT_CAFETERIA_7,
    project_full_NIT_CAFETERIA_8,
    project_full_NIT_CAFETERIA_9,
    project_full_NIT_CAFETERIA_10,

    //project full NIT Landscape
    project_full_NIT_LANDSCAPE_1,
    project_full_NIT_LANDSCAPE_2,
    project_full_NIT_LANDSCAPE_3,
    project_full_NIT_LANDSCAPE_4,

    //project full NSCB University
    project_full_NSCB_UNIVERSITY_1,

    //project full NTPC PORTA
    project_full_NTPC_PORTA_1,
    project_full_NTPC_PORTA_2,
    project_full_NTPC_PORTA_3,
    project_full_NTPC_PORTA_4,

    //project full QUINTEX
    project_full_QUINTEX_1,
    project_full_QUINTEX_2,
    project_full_QUINTEX_3,
    project_full_QUINTEX_4,
    project_full_QUINTEX_5,
    project_full_QUINTEX_6,
    project_full_QUINTEX_7,
    project_full_QUINTEX_8,
    project_full_QUINTEX_9,

    //project full BACHELOR ACCOMODATION ONGC
    project_full_BACHELOR_ACCOMODATION_ONGC_1,
    project_full_BACHELOR_ACCOMODATION_ONGC_2,

    //project full PEERLESS 2
    project_full_PEERLES_2_01,
    project_full_PEERLES_2_02,

    //project full PEERLESS 1
    project_full_PEERLES_1_01,
    project_full_PEERLES_1_02,

    //project full SITE OFFICE AASTHA
    project_full_SITE_OFFICE_AASTHA_1,
    project_full_SITE_OFFICE_AASTHA_2,
    project_full_SITE_OFFICE_AASTHA_3,

    //project full SLDC JAMSHEDPUR
    project_full_SLDC_JAMSHEDPUR_1,
    project_full_SLDC_JAMSHEDPUR_2,
    project_full_SLDC_JAMSHEDPUR_3,

    //project full STUDIO APARTMENT
    project_full_STUDIO_APARTMENT_1,
    project_full_STUDIO_APARTMENT_2,
    project_full_STUDIO_APARTMENT_3,

    //project full SMART MODULAR TOILET
    project_full_SMART_MODULAR_TOILET_1,
    project_full_SMART_MODULAR_TOILET_2,
    project_full_SMART_MODULAR_TOILET_3,
    project_full_SMART_MODULAR_TOILET_4,
    project_full_SMART_MODULAR_TOILET_5,
    project_full_SMART_MODULAR_TOILET_6,
    project_full_SMART_MODULAR_TOILET_7,
    project_full_SMART_MODULAR_TOILET_8,
    project_full_SMART_MODULAR_TOILET_9,
    project_full_SMART_MODULAR_TOILET_10,
    project_full_SMART_MODULAR_TOILET_11,
    project_full_SMART_MODULAR_TOILET_12,
    project_full_SMART_MODULAR_TOILET_13,
    project_full_SMART_MODULAR_TOILET_14,
    project_full_SMART_MODULAR_TOILET_15,
    project_full_SMART_MODULAR_TOILET_16,

    //project full TUBE MAKERS CLUB
    project_full_TUBE_MAKERS_CLUB_1,
    project_full_TUBE_MAKERS_CLUB_2,

    //project full WEST BOKARA TOWNSHIP
    project_full_WEST_BOKARA_TOWNSHIP_1,
    project_full_WEST_BOKARA_TOWNSHIP_2,
    project_full_WEST_BOKARA_TOWNSHIP_3,
    project_full_WEST_BOKARA_TOWNSHIP_4,
    project_full_WEST_BOKARA_TOWNSHIP_5,

    // thumbnails
    project__thumb_BPCL_IN_OUT_STORE,
    project__thumb_COVID_HOSPITAL,
    project__thumb_CREMATORIUM,
    project__thumb_JAQUAR_RETAIL_STORE,
    project__thumb_MOHALLA_CLINIC,
    project__thumb_MTMC_LIBRARY,
    project__thumb_NARULA_INSTITUTE_CAFETERIA,
    project__thumb_NDITA_ICONIC_GATE,
    project__thumb_PARO_AIRPORT,
    project__thumb_TSJ_COKE_PLANT,
    project__thumb_VEDANTA_TOWNSHIP,
    project_thumb_MILI_APARTMENT,
    project_thumb_JOYVILLE_APARTMENTS,
    project_thumb_PADMALAYA,
    project_thumb_BOHATA_3BHK,
    project_thumb_SRIJAN_SOUTHWIND,
    project_thumb_BOHATA_6BHK,
    project_thumb_ARUN_KRIPAL_RESIDENCE,
    project_thumb_BPCL_IN_AND_OUT,
    project_thumb_CIIIT_MANIPUR,
    project_thumb_COMMENTRY_BOX_SCA,
    project_thumb_CRC_TARAPUR,
    project_thumb_CRECHE_FACILITY,
    project_thumb_DUPLEX_DHANBAD,
    project_thumb_GM_QSEB,
    project_thumb_HIGHRISE_JAMSHEDPUR,
    project_thumb_NOAMUNDI_HOSPITAL,
    project_thumb_ILLUSION_ART,
    project_thumb_JRD_TATA_GATE,
    project_thumb_MAHE,
    project_thumb_NEELKAMAL,
    project_thumb_NIT_CAFETERIA,
    project_thumb_NIT_LANDSCAPE,
    project_thumb_NSCB_UNIVERSITY,
    project_thumb_NTPC_PORTA,
    project_thumb_QUINTEX,
    project_thumb_BACHELOR_ACCOMODATION_ONGC,
    project_thumb_PEERLES_2,
    project_thumb_PEERLEES_1,
    project_thumb_SITE_OFFICE_AASTHA,
    project_thumb_SLDC_JAMSHEDPUR,
    project_thumb_SMART_MODULAR_TOILET,
    project_thumb_STUDIO_APARTMENT,
    project_thumb_TUBE_MAKERS_CLUB,
    project_thumb_WEST_BOKARA_TOWNSHIP,

    // CREMATORIUM
    project__CREMATORIUM1,
    project__CREMATORIUM2,
    project__CREMATORIUM3,
    project__CREMATORIUM4,
    project__CREMATORIUM5,

    // ILLUSION_ART_RAILING
    project__ILLUSION_ART_RAILING1,
    project__ILLUSION_ART_RAILING2,
    project__ILLUSION_ART_RAILING3,
    project__ILLUSION_ART_RAILING4,
    project__ILLUSION_ART_RAILING5,
    project__ILLUSION_ART_RAILING6,
    project__ILLUSION_ART_RAILING7,
    project__ILLUSION_ART_RAILING8,
    project__ILLUSION_ART_RAILING9,
    project__ILLUSION_ART_RAILING11,
    project__ILLUSION_ART_RAILING12,

    // JAQUAR
    project__JAQUAR1,
    project__JAQUAR2,
    project__JAQUAR3,
    project__JAQUAR4,
    project__JAQUAR5,
    project__JAQUAR6,

    // MOHALLA_CLINIC
    project__MOHALLA_CLINIC1,
    project__MOHALLA_CLINIC2,
    project__MOHALLA_CLINIC3,
    project__MOHALLA_CLINIC4,
    project__MOHALLA_CLINIC5,

    // MTMC
    project__MTMC1,
    project__MTMC2,
    project__MTMC3,
    project__MTMC4,

    // NARULA_CAFE
    project__NARULA_CAFE1,
    project__NARULA_CAFE2,
    project__NARULA_CAFE3,
    project__NARULA_CAFE4,
    project__NARULA_CAFE5,

    // NDITA_ICONIC_GATE
    project__NDITA_ICONIC_GATE1,
    project__NDITA_ICONIC_GATE2,
    project__NDITA_ICONIC_GATE3,

    // PARO_AIRPORT
    project__PARO_AIRPORT1,
    project__PARO_AIRPORT2,
    project__PARO_AIRPORT3,
} from "../helpers/Images";

export const ProjectsCategories = [
    { id: "c1", cat: "STATUS" },
    { id: "c2", cat: "CHRONOLOGICAL" },
];

export const ProjectStatus = [
    { id: "ps1", status: "COMPLETED" },
    { id: "ps2", status: "PLANNING" },
    { id: "ps3", status: "PROGRESS" },
];
export const ProjectYears = [
    { id: "py3", year: "2022" },
    { id: "py1", year: "2021" },
    { id: "py2", year: "2020" },
];
export const ProjectCat = [
    { id: "py1", cat: "ALL" },
    { id: "py2", cat: "INTERIOR" },
    { id: "py3", cat: "ARCHITECTURE" },
    { id: "py4", cat: "INSTALLATION" },
    { id: "py5", cat: "MODULAR" },
    { id: "py6", cat: "TOWNSHIP" },
];
export const ProjectsData = [
    {
        id: "pro1",
        year: "2020",
        projectName: "MTMC Library",
        projectThumb: project__thumb_MTMC_LIBRARY,
        full_images: [
            project__full_MTMC_LIBRARY,
            project__MTMC1,
            //  project__MTMC2,
            project__MTMC3,
            project__MTMC4,
        ],
        status: "Completed",
        featured: false,
        description:
            "Our first hybrid structure with a combination of ISMB Steel Section and walls made up of LGSF members with board cladding. This is a complete new-age dry construction methododlogy.",
    },
    {
        id: "pro2",
        year: "2022",
        projectName: "TSJ Coke Plant",
        projectThumb: project__thumb_TSJ_COKE_PLANT,
        full_images: [
            project__full_TSJ_COKE_PLANT_4,
            // project__full_TSJ_COKE_PLANT,
            project__full_TSJ_COKE_PLANT_1,
            project__full_TSJ_COKE_PLANT_2,
            project__full_TSJ_COKE_PLANT_3,
        ],
        status: "Completed",
        featured: true,
        description:
            "This is another hybrid steel building situated in the heart of TATA Steel Jamshedpur Coke Plant. It is the Canteen Building & Office for the Chiefs.",
    },
    {
        id: "pro3",
        year: "2022",
        projectName: "Illusion Art Railing",
        projectThumb: project_thumb_ILLUSION_ART,
        full_images: [
            project__full_ILLUSION_ART_RAILING,
            project__ILLUSION_ART_RAILING1,
            project__ILLUSION_ART_RAILING2,
            project__ILLUSION_ART_RAILING3,
            project__ILLUSION_ART_RAILING4,
            project__ILLUSION_ART_RAILING5,
            project__ILLUSION_ART_RAILING6,
            project__ILLUSION_ART_RAILING7,
            project__ILLUSION_ART_RAILING8,
            project__ILLUSION_ART_RAILING9,
            project__ILLUSION_ART_RAILING11,
            project__ILLUSION_ART_RAILING12,
        ],
        status: "Completed",
        featured: false,
        description:
            "A first of its type in the City that enhances the beauty and complements the Green patches and forms an everflowing dynamic storyboard which one can enjoy through a comfort ride on the arterial road.",
    },
    {
        id: "pro4",
        year: "2022",
        projectName: "Mohalla Clinic",
        projectThumb: project__thumb_MOHALLA_CLINIC,
        full_images: [
            project__full_MOHALLA_CLINIC,
            // project__MOHALLA_CLINIC1,
            project__MOHALLA_CLINIC2,
            project__MOHALLA_CLINIC3,
            project__MOHALLA_CLINIC4,
            project__MOHALLA_CLINIC5,
        ],
        status: "Completed",
        featured: false,
        description:
            "A mobile clinic that can be set-up anywhere on a small patch of land and acts as an Plug-and-Play Building with construction time of less than 3 weeks at the most.",
    },
    {
        id: "pro5",
        year: "2021",
        projectName: "BPCL In & Out Store",
        projectThumb: project__thumb_BPCL_IN_OUT_STORE,
        full_images: [project__full_BPCL_IN_OUT_STORE],
        status: "Completed",
        featured: false,
        description:
            "A drive-in convinient store that one can check-out at the BPCL Petrol Pumps all over India with all mordern facilities and items from daily need.",
    },
    {
        id: "pro6",
        year: "2021",
        projectName: "Retail Store Outlet Designs, India",
        projectThumb: project__thumb_JAQUAR_RETAIL_STORE,
        full_images: [project__JAQUAR1, project__JAQUAR2, project__JAQUAR3, project__JAQUAR4, project__JAQUAR5, project__JAQUAR6],
        status: "Completed",
        category: "Interior",
        featured: false,
        description:
            "The retail stores have been designed using the client briefs in mind. Jaquar has its own style and VBRIDGE worked to enhance the given parameters at each of its unique stores across eastern India as its Official Design Partner.",
    },
    {
        id: "pro7",
        year: "2020",
        projectName: "Narula Institute Cafeteria",
        projectThumb: project__thumb_NARULA_INSTITUTE_CAFETERIA,
        full_images: [
            project__full_NARULA_INSTITUTE_CAFETERIA,
            project__NARULA_CAFE1,
            project__NARULA_CAFE2,
            project__NARULA_CAFE3,
            project__NARULA_CAFE4,
            project__NARULA_CAFE5,
        ],
        status: "Completed",
        featured: false,
        description:
            "A minimalistic and rustic College Cafeteria Design with a variety of seating spaces and typologies that is sure to be the memorable Hangout & Gossip hub.",
    },
    {
        id: "pro8",
        year: "2020",
        projectName: "Paro International Airport Domestic terminal",
        projectThumb: project__thumb_PARO_AIRPORT,
        full_images: [
            // project__full_PARO_AIRPORT,
            project__PARO_AIRPORT1,
            project__PARO_AIRPORT2,
            project__PARO_AIRPORT3,
        ],
        status: "Planning",
        featured: true,
        description:
            "A brand new Design & Contruction for the Paro Domestic Terminal & GSE Ramp Shed for the increasing over-flow of Tourism in Bhutan by the Bhutan Government. It is a blend of Traditional Bhutanese Architecture & Contemporary Interior to enhance the beauty of the Gateway to Bhutan.",
    },
    {
        id: "pro9",
        year: "2021",
        projectName: "Sustainable crematorium model",
        projectThumb: project__thumb_CREMATORIUM,
        full_images: [
            project__full_CREMATORIUM,
            project__CREMATORIUM1,
            project__CREMATORIUM2,
            project__CREMATORIUM3,
            project__CREMATORIUM4,
            project__CREMATORIUM5,
        ],
        status: "Planning",
        featured: false,
        description:
            "A green initiative and a prototype that runs of conventional cremation techniques and not electricity. The gases as purified through a complex mechanism of air purification system and are released in the atmosphere as mere clean smoke thus reducing on the carbon emisson.",
    },
    {
        id: "pro10",
        year: "2021",
        projectName: "NDITA Iconic Gate",
        projectThumb: project__thumb_NDITA_ICONIC_GATE,
        full_images: [
            project__full_NDITA_ICONIC_GATE,
            project__NDITA_ICONIC_GATE1,
            project__NDITA_ICONIC_GATE2,
            //   project__NDITA_ICONIC_GATE3
        ],
        status: "Progress",
        featured: true,
        description:
            "An Iconic Structure and Gateway to the Silicon Valley and IT Hub of Bengal. Arguably the first Glasswalk of Kolkata with an elevated Green Recluse.",
    },
    {
        id: "pro11",
        year: "2021",
        projectName: "Vedanta Township",
        projectThumb: project__thumb_VEDANTA_TOWNSHIP,
        full_images: [project__full_VEDANTA_TOWNSHIP],
        status: "Progress",
        featured: false,
        description: "Concept of Row Housing in Bungalow Style where only two units are combined and clustered in a neighbourhood layout concept.",
    },
    {
        id: "pro12",
        year: "2021",
        projectName: "Covid hospitals",
        projectThumb: project__thumb_COVID_HOSPITAL,
        full_images: [project__full_COVID_HOSPITAL_1, project__full_COVID_HOSPITAL_2, project__full_COVID_HOSPITAL_3, project__full_COVID_HOSPITAL_4],
        status: "Completed",
        featured: false,
        description:
            "Designed as a prototype to be replicated in various places to cater to the COVID patients. It was designed with LGSF structure for fast construction and mobilisation.",
    },
    // new added

    {
        id: "pro13",
        year: "2022",
        projectName: "Mili Home Boutique, Kolkata",
        client: "PRIVATE",
        projectArea: "450",
        projectValue: "10 LACS",
        constructionPeriod: "3 MONTHS",
        designTopology: "Residential",
        projectThumb: project_thumb_MILI_APARTMENT,
        full_images: [project_full_MILI_APARTMENT_1, project_full_MILI_APARTMENT_3, project_full_MILI_APARTMENT_4],
        category: "Interior",
        status: "Completed",
        featured: false,
        description:
            "The Project has been visualized with the idea of having a personal boutique within own home. It has been thoroughly crafted using the client's tight brief in mind",
    },
    {
        id: "pro14",
        year: "2022",
        projectName: "Joy House, Howrah",
        client: "Amit Kr. Santra",
        projectArea: "1000",
        constructionPeriod: "",
        designTopology: "RESIDENTIAL",
        projectThumb: project_thumb_JOYVILLE_APARTMENTS,
        full_images: [
            project_full_JOYVILLE_APARTMENT_1,
            project_full_JOYVILLE_APARTMENT_2,
            project_full_JOYVILLE_APARTMENT_3,
            project_full_JOYVILLE_APARTMENT_4,
        ],
        category: "Interior",
        status: "Completed",
        featured: false,
        description:
            "Minimalist is the word used by the client. Low budget yet a decent interior is what the client was looking for. Completed execution within just one & half month.",
    },
    {
        id: "pro15",
        year: "2022",
        projectName: "Padma Alay, Kolkata",
        client: "",
        projectArea: "",
        constructionPeriod: "",
        designTopology: "",
        projectThumb: project_thumb_PADMALAYA,
        full_images: [project_full_PADMALYA_1, project_full_PADMALYA_2, project_full_PADMALYA_3, project_full_PADMALYA_4, project_full_PADMALYA_5],
        category: "Interior",
        status: "Completed",
        featured: false,
        description:
            "This design has a story where a newly wed couple required a personal touch for creating the best ambience. Designed and executed within a very short span of time.",
    },
    {
        id: "pro16",
        year: "2022",
        projectName: "Bahota Residence, Cooch Behar",
        client: "Debashis Das",
        projectArea: "950",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_BOHATA_3BHK,
        full_images: [project_full_BOHATA_3BHK_1, project_full_BOHATA_3BHK_2, project_full_BOHATA_3BHK_3, project_full_BOHATA_3BHK_4],
        category: "Interior",
        status: "Ongoing",
        featured: false,
        description:
            "Royal touch is what the name suggests. The Client brief was taken & a five member team completed the design task within a week to convert into execution",
    },
    {
        id: "pro17",
        year: "2022",
        projectName: "Southwinds, Kolkata",
        client: "Arpita Chatterjee",
        projectArea: "1115",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_SRIJAN_SOUTHWIND,
        full_images: [project_full_SRIJAN_SOUTHWIND_1, project_full_SRIJAN_SOUTHWIND_2, project_full_SRIJAN_SOUTHWIND_3],
        category: "Interior",
        status: "Unbuilt",
        featured: false,
        description:
            "Keeping the Daily requirements in mind, this has been designed to keep the simplicty high. The Design has been done keep client's taste of colors in mind.",
    },
    {
        id: "pro18",
        year: "2022",
        projectName: "Bahota Villa, Cooch Behar",
        client: "Debashis Das",
        projectArea: "",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_BOHATA_6BHK,
        full_images: [
            project_full_BOHATA_6BHK_1,
            project_full_BOHATA_6BHK_2,
            project_full_BOHATA_6BHK_3,
            project_full_BOHATA_6BHK_4,
            project_full_BOHATA_6BHK_5,
            project_full_BOHATA_6BHK_6,
        ],
        category: "Interior",
        status: "Ongoing",
        featured: false,
        description:
            "Elegance & Heritage was the brief from the Client. This combination was challenging & unique in terms of Interior Design but successfully ongoing execution presently.",
    },
    {
        id: "pro19",
        year: "2022",
        projectName: "Arun Kripal",
        client: "Nestin",
        projectArea: "2800",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_ARUN_KRIPAL_RESIDENCE,
        full_images: [project_full_ARUN_KRIPAL_RESIDENCE_1, project_full_ARUN_KRIPAL_RESIDENCE_2, project_full_ARUN_KRIPAL_RESIDENCE_3],
        category: "Architecture",
        status: "Completed",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro20",
        year: "2022",
        projectName: "BPCL In and Out",
        client: "Nestin",
        projectArea: "400",
        constructionPeriod: "",
        designTopology: "Commercial",
        projectThumb: project_thumb_BPCL_IN_AND_OUT,
        full_images: [project__full_BPCL_IN_OUT_1],
        category: "Architecture",
        status: "Completed",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro21",
        year: "2022",
        projectName: "CIIIT Manipur",
        client: "Nestin",
        projectArea: "18650",
        constructionPeriod: "",
        designTopology: "Institutional",
        projectThumb: project_thumb_CIIIT_MANIPUR,
        full_images: [project_full_CIIIT_MANIPUR_1, project_full_CIIIT_MANIPUR_2],
        category: "Architecture",
        status: "Ongoing",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro22",
        year: "2022",
        projectName: "Sikkim Cricket",
        client: "Nestin",
        projectArea: "2000",
        constructionPeriod: "",
        designTopology: "Recreational",
        projectThumb: project_thumb_COMMENTRY_BOX_SCA,
        full_images: [project_full_project_thumb_COMMENTRY_BOX_SCA_1, project_full_project_thumb_COMMENTRY_BOX_SCA_2],
        category: "Modular",
        status: "Completed",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro23",
        year: "2022",
        projectName: "CRC TARAPUR",
        client: "TATA Structura",
        projectArea: "2000",
        constructionPeriod: "",
        designTopology: "Gate",
        projectThumb: project_thumb_CRC_TARAPUR,
        full_images: [project_full_CRC_TARAPUR_1, project_full_CRC_TARAPUR_2, project_full_CRC_TARAPUR_3],
        category: "Installation",
        status: "Ongoing",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro24",
        year: "2022",
        projectName: "Creche Facility",
        client: "Nestin",
        projectArea: "800",
        constructionPeriod: "",
        designTopology: "Commercial",
        projectThumb: project_thumb_CRECHE_FACILITY,
        full_images: [project_full_CRECHE_FACILITY_1, project_full_CRECHE_FACILITY_2, project_full_CRECHE_FACILITY_3, project_full_CRECHE_FACILITY_4],
        category: "Modular",
        status: "Unbuilt",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro25",
        year: "2022",
        projectName: "Duplex Dhanbad",
        client: "Aastha Group",
        projectArea: "200000",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_DUPLEX_DHANBAD,
        full_images: [project_full__DUPLEX_DHANBAD_1, project_full__DUPLEX_DHANBAD_2, project_full__DUPLEX_DHANBAD_3],
        category: "Architecture",
        status: "Unbuilt",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro26",
        year: "2022",
        projectName: "GM QSEB",
        client: "Nestin",
        projectArea: "3725",
        constructionPeriod: "",
        designTopology: "Office",
        projectThumb: project_thumb_GM_QSEB,
        full_images: [project_full_GM_QSEB_1, project_full_GM_QSEB_2, project_full_GM_QSEB_3],
        category: "Architecture",
        status: "Completed",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro27",
        year: "2022",
        projectName: "Highrise Jamshedpur",
        client: "Aastha Group",
        projectArea: "2100000",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_HIGHRISE_JAMSHEDPUR,
        full_images: [
            project_full_HIGHRISE_JAMSHEDPUR_1,
            project_full_HIGHRISE_JAMSHEDPUR_2,
            project_full_HIGHRISE_JAMSHEDPUR_3,
            project_full_HIGHRISE_JAMSHEDPUR_4,
            project_full_HIGHRISE_JAMSHEDPUR_5,
            project_full_HIGHRISE_JAMSHEDPUR_6,
            project_full_HIGHRISE_JAMSHEDPUR_7,
            project_full_HIGHRISE_JAMSHEDPUR_8,
            project_full_HIGHRISE_JAMSHEDPUR_9,
            project_full_HIGHRISE_JAMSHEDPUR_10,
            project_full_HIGHRISE_JAMSHEDPUR_11,
            project_full_HIGHRISE_JAMSHEDPUR_12,
            project_full_HIGHRISE_JAMSHEDPUR_13,
            project_full_HIGHRISE_JAMSHEDPUR_14,
            project_full_HIGHRISE_JAMSHEDPUR_15,
            project_full_HIGHRISE_JAMSHEDPUR_16,
            project_full_HIGHRISE_JAMSHEDPUR_17,
            project_full_HIGHRISE_JAMSHEDPUR_18,
            project_full_HIGHRISE_JAMSHEDPUR_19,
            project_full_HIGHRISE_JAMSHEDPUR_20,
        ],
        category: "Architecture",
        status: "Unbuilt",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro28",
        year: "2022",
        projectName: "Noamundi Hospital",
        client: "Nestin",
        projectArea: "19900",
        constructionPeriod: "",
        designTopology: "Healthcare",
        projectThumb: project_thumb_NOAMUNDI_HOSPITAL,
        full_images: [project_full_NOAMUNDI_HOSPITAL_1, project_full_NOAMUNDI_HOSPITAL_2, project_full_NOAMUNDI_HOSPITAL_3],
        category: "Architecture",
        status: "Ongoing",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro29",
        year: "2022",
        projectName: "JRD TATA Gate",
        client: "TATA Structura",
        projectArea: "19900",
        constructionPeriod: "",
        designTopology: "Gate",
        projectThumb: project_thumb_JRD_TATA_GATE,
        full_images: [
            project_full_JRD_TATA_GATE_1,
            project_full_JRD_TATA_GATE_2,
            project_full_JRD_TATA_GATE_3,
            project_full_JRD_TATA_GATE_4,
            project_full_JRD_TATA_GATE_5,
            project_full_JRD_TATA_GATE_6,
        ],
        category: "Installation",
        status: "Ongoing",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro30",
        year: "2022",
        projectName: "MAHE",
        client: "Nestin",
        projectArea: "55400",
        constructionPeriod: "",
        designTopology: "Institutional",
        projectThumb: project_thumb_MAHE,
        full_images: [
            project_full_MAHE_1,
            project_full_MAHE_2,
            project_full_MAHE_3,
            project_full_MAHE_4,
            project_full_MAHE_5,
            project_full_MAHE_6,
            project_full_MAHE_7,
        ],
        category: "Architecture",
        status: "Completed",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro31",
        year: "2022",
        projectName: "Neel Kamal, Kolkata",
        client: "",
        projectArea: "",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_NEELKAMAL,
        full_images: [project_full_NEELKAMAL_1, project_full_NEELKAMAL_2, project_full_NEELKAMAL_3],
        category: "Interior",
        status: "Unbuilt",
        featured: false,
        description:
            "The dusky interiors were seeked by the client given their designer brief. All along the project, smart technologies have been briefly utilised in the best way possible.",
    },
    {
        id: "pro32",
        year: "2022",
        projectName: "NIT Students' Cafeteria",
        client: "Jis Group",
        projectArea: "",
        constructionPeriod: "",
        designTopology: "Institutional",
        projectThumb: project_thumb_NIT_CAFETERIA,
        full_images: [
            project_full_NIT_CAFETERIA_1,
            project_full_NIT_CAFETERIA_2,
            project_full_NIT_CAFETERIA_3,
            project_full_NIT_CAFETERIA_4,
            project_full_NIT_CAFETERIA_5,
            project_full_NIT_CAFETERIA_6,
            project_full_NIT_CAFETERIA_7,
            project_full_NIT_CAFETERIA_8,
            project_full_NIT_CAFETERIA_9,
            project_full_NIT_CAFETERIA_10,
        ],
        category: "Interior",
        status: "Completed",
        featured: false,
        description:
            "Five labs of the college were converted into a Students Café within 2.5 months including execution. A team of 40 Members have worked to complete the job that has industrial look mixed with college vibrance in the design.",
    },
    {
        id: "pro33",
        year: "2022",
        projectName: "NIT Landscape",
        client: "Jis Group",
        projectArea: "",
        constructionPeriod: "",
        designTopology: "Institutional",
        projectThumb: project_thumb_NIT_LANDSCAPE,
        full_images: [project_full_NIT_LANDSCAPE_1, project_full_NIT_LANDSCAPE_2, project_full_NIT_LANDSCAPE_3, project_full_NIT_LANDSCAPE_4],
        category: "Interior",
        status: "Completed",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro34",
        year: "2022",
        projectName: "NSCB University",
        client: "GOVT. OF W.B.",
        projectArea: "",
        constructionPeriod: "",
        designTopology: "Institutional",
        projectThumb: project_thumb_NSCB_UNIVERSITY,
        full_images: [project_full_NSCB_UNIVERSITY_1],
        category: "Architecture",
        status: "Unbuilt",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro35",
        year: "2022",
        projectName: "NTPC PORTA",
        client: "Nestin",
        projectArea: "200",
        constructionPeriod: "",
        designTopology: "Office",
        projectThumb: project_thumb_NTPC_PORTA,
        full_images: [project_full_NTPC_PORTA_1, project_full_NTPC_PORTA_2, project_full_NTPC_PORTA_3, project_full_NTPC_PORTA_4],
        category: "Modular",
        status: "Completed",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro36",
        year: "2022",
        projectName: "Quintex Office, Kolkata",
        client: "Quintex Software Solution",
        projectArea: "1350",
        constructionPeriod: "",
        designTopology: "Office",
        projectThumb: project_thumb_QUINTEX,
        full_images: [
            project_full_QUINTEX_1,
            project_full_QUINTEX_2,
            project_full_QUINTEX_3,
            project_full_QUINTEX_4,
            project_full_QUINTEX_5,
            project_full_QUINTEX_6,
            project_full_QUINTEX_7,
            project_full_QUINTEX_8,
            project_full_QUINTEX_9,
        ],
        category: "Interior",
        status: "Completed",
        featured: false,
        description:
            "The Office Interior was a Modern yet industrial look approach given the client's taste in mind. Vibrant colors with dark ceiling & warm lights have created a very valuable space for the user group.",
    },
    {
        id: "pro37",
        year: "2022",
        projectName: "Bachelor Accomodation ONGC",
        client: "Nestin",
        projectArea: "9400",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_BACHELOR_ACCOMODATION_ONGC,
        full_images: [project_full_BACHELOR_ACCOMODATION_ONGC_1, project_full_BACHELOR_ACCOMODATION_ONGC_2],
        category: "Architecture",
        status: "Ongoing",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro38",
        year: "2022",
        projectName: "Peerless 002",
        client: "Dhara Group",
        projectArea: "13500",
        constructionPeriod: "",
        designTopology: "Mixed Use",
        projectThumb: project_thumb_PEERLES_2,
        full_images: [project_full_PEERLES_2_01, project_full_PEERLES_2_02],
        category: "Architecture",
        status: "Unbuilt",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro39",
        year: "2022",
        projectName: "Peerless 001",
        client: "Dhara Group",
        projectArea: "14200",
        constructionPeriod: "",
        designTopology: "Mixed Use",
        projectThumb: project_thumb_PEERLEES_1,
        full_images: [project_full_PEERLES_1_01, project_full_PEERLES_1_02],
        category: "Architecture",
        status: "Unbuilt",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro40",
        year: "2022",
        projectName: "Site Office Aastha",
        client: "Aastha Group",
        projectArea: "1400",
        constructionPeriod: "",
        designTopology: "Office",
        projectThumb: project_thumb_SITE_OFFICE_AASTHA,
        full_images: [project_full_SITE_OFFICE_AASTHA_1, project_full_SITE_OFFICE_AASTHA_2, project_full_SITE_OFFICE_AASTHA_3],
        category: "Architecture",
        status: "Unbuilt",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro41",
        year: "2022",
        projectName: "SLDC Jamshedpur",
        client: "Nestin",
        projectArea: "44000",
        constructionPeriod: "",
        designTopology: "Office",
        projectThumb: project_thumb_SLDC_JAMSHEDPUR,
        full_images: [project_full_SLDC_JAMSHEDPUR_1, project_full_SLDC_JAMSHEDPUR_2, project_full_SLDC_JAMSHEDPUR_3],
        category: "Architecture",
        status: "Unbuilt",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro42",
        year: "2022",
        projectName: "Smart Modular Toilet",
        client: "Nestin",
        projectArea: "160",
        constructionPeriod: "",
        designTopology: "Toilet",
        projectThumb: project_thumb_SMART_MODULAR_TOILET,
        full_images: [
            project_full_SMART_MODULAR_TOILET_1,
            project_full_SMART_MODULAR_TOILET_2,
            project_full_SMART_MODULAR_TOILET_3,
            project_full_SMART_MODULAR_TOILET_4,
            project_full_SMART_MODULAR_TOILET_5,
            project_full_SMART_MODULAR_TOILET_6,
            project_full_SMART_MODULAR_TOILET_7,
            project_full_SMART_MODULAR_TOILET_8,
            project_full_SMART_MODULAR_TOILET_9,
            project_full_SMART_MODULAR_TOILET_10,
            project_full_SMART_MODULAR_TOILET_11,
            project_full_SMART_MODULAR_TOILET_12,
            project_full_SMART_MODULAR_TOILET_13,
            project_full_SMART_MODULAR_TOILET_14,
            project_full_SMART_MODULAR_TOILET_15,
            project_full_SMART_MODULAR_TOILET_16,
        ],
        category: "Modular",
        status: "Completed",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro43",
        year: "2022",
        projectName: "Studio Apartment",
        client: "Nestin",
        projectArea: "40800",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_STUDIO_APARTMENT,
        full_images: [project_full_STUDIO_APARTMENT_1, project_full_STUDIO_APARTMENT_2, project_full_STUDIO_APARTMENT_3],
        category: "Architecture",
        status: "Ongoing",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro44",
        year: "2022",
        projectName: "Tube Makers Club",
        client: "TATA Structura",
        projectArea: "",
        constructionPeriod: "",
        designTopology: "Sculptural",
        projectThumb: project_thumb_TUBE_MAKERS_CLUB,
        full_images: [project_full_TUBE_MAKERS_CLUB_1, project_full_TUBE_MAKERS_CLUB_2],
        category: "Installation",
        status: "Ongoing",
        featured: false,
        description: "Placeholder",
    },
    {
        id: "pro45",
        year: "2022",
        projectName: "West Bokara Township",
        client: "Nestin",
        projectArea: "46800",
        constructionPeriod: "",
        designTopology: "Residential",
        projectThumb: project_thumb_WEST_BOKARA_TOWNSHIP,
        full_images: [
            project_full_WEST_BOKARA_TOWNSHIP_1,
            project_full_WEST_BOKARA_TOWNSHIP_2,
            project_full_WEST_BOKARA_TOWNSHIP_3,
            project_full_WEST_BOKARA_TOWNSHIP_4,
            project_full_WEST_BOKARA_TOWNSHIP_5,
        ],
        category: "Township",
        status: "Ongoing",
        featured: false,
        description: "Placeholder",
    },
];
