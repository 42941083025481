export const FaqData = [
    {
        qes: "CRITERIAS TO JOIN VBRIDGE",
        ans: `Aliquam ultricies nulla consequat sed aliquam sed consequat. Nisl, sollicitudin auctor fermentum,
      pellentesque facilisi elementum viverra. Vestibulum volutpat laoreet semper urna.`,
    },

    {
        qes: "GENERAL DEADLINES OF VACANCY",
        ans: `Aliquam ultricies nulla consequat sed aliquam sed consequat. Nisl, sollicitudin auctor fermentum,
      pellentesque facilisi elementum viverra. Vestibulum volutpat laoreet semper urna.`,
    },

    {
        qes: "DOES VBRIDGE WORK ON SMALLER SCALE PROJECTS",
        ans: `Aliquam ultricies nulla consequat sed aliquam sed consequat. Nisl, sollicitudin auctor fermentum,
      pellentesque facilisi elementum viverra. Vestibulum volutpat laoreet semper urna.`,
    },
    {
        qes: "WHAT IS VBRIDGE COMMUNITY DESIGN WORKSHOP",
        ans: `Aliquam ultricies nulla consequat sed aliquam sed consequat. Nisl, sollicitudin auctor fermentum,
      pellentesque facilisi elementum viverra. Vestibulum volutpat laoreet semper urna.`,
    },
    {
        qes: "WHICH PLACE CAN WE GET SERVICE OF VBRIDGE?",
        ans: `Aliquam ultricies nulla consequat sed aliquam sed consequat. Nisl, sollicitudin auctor fermentum,
      pellentesque facilisi elementum viverra. Vestibulum volutpat laoreet semper urna.`,
    },
];
