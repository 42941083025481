import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CLoader, FaqAccordion, FloatingSocial, Wrapper } from "../components";
import { Footer, Header } from "../layout";

import { FaqData } from "../data/FaqData";
import { Scroll } from "../components/animation";

const Faq = () => {
    const [Cloader, setCloader] = useState(true);
    useEffect(() => {
        setTimeout(() => {
            setCloader(false);
        }, 1000);
    }, []);
    return (
        <Wrapper id="faq" pageKey="vbridge__faq__page">
            {/* header */}
            <Header />
            {/* header end */}
            {Cloader ? (
                <CLoader />
            ) : (
                <>
                    {/* faq content */}
                    <div className="content">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9">
                                    <Scroll uqkey="faq__questions">
                                        <h2 className="mt-3">Your queries, Our answers!</h2>
                                        <div className="questions">
                                            {FaqData.map(({ qes, ans }) => (
                                                <FaqAccordion question={qes} answer={ans} />
                                            ))}
                                        </div>
                                    </Scroll>
                                </div>

                                <div className="col-lg-3">
                                    <div className="sidebar">
                                        <Scroll uqkey="faq__sidebar">
                                            <h3>Content</h3>
                                            <h5>Diam in cursus</h5>
                                            <h5>Eget Vitae</h5>
                                            <h5>Egestas Iaculis</h5>

                                            <h3 className="mt-5">Quick Links</h3>
                                            <Link to="/about">
                                                <h5>About Us</h5>
                                            </Link>

                                            <Link to="/contact">
                                                <h5>Contact</h5>
                                            </Link>
                                            <Link to="/news">
                                                <h5>News</h5>
                                            </Link>
                                            <Link to="/terms">
                                                <h5>Terms and Conditions</h5>
                                            </Link>
                                        </Scroll>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* faq content end */}

                    {/* footer */}
                    <Footer />
                    {/* footer end */}
                </>
            )}

            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}
        </Wrapper>
    );
};

export default Faq;
