import React, { useState } from "react";

// react carousel
import { Carousel } from "react-responsive-carousel";

// framer motion
import { AnimatePresence } from "framer-motion";

// custom small screen checker helper
import { useIsSmall } from "../../../helpers/Hooks";
import { FadeOutTimer, FadeOutWithTimer, SlideOut, SlideOutText } from "../../animation";

// images
import { main__logo, home__Pattern, dim, IntroGif } from "../../../helpers/Images";

// videos
import { HomeIntroSlider } from "../../../data/HomeData";

import CLoader from "../../base/loader/CLoader";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { ImFacebook2, ImLinkedin } from "react-icons/im";
import { AiFillInstagram } from "react-icons/ai";
import { IoLogoYoutube } from "react-icons/io";

const HomeIntro = () => {
    // small screen checker
    const isSmall = useIsSmall();

    // video end check state
    const [animationComplete, setAnimationComplete] = useState(false);

    const animationExitHandler = (e) => {
        setAnimationComplete(e);
    };

    const [loading, setLoading] = useState(true);

    return (
        <>
            {loading && <CLoader />}

            {!isSmall ? (
                <>
                    {/* home gif */}
                    {/* {!animationComplete && (
                        <FadeOutWithTimer delay={6.5} aniExited={(e) => setAnimationComplete(e)}>
                            <div className="intro-gif">
                                <img src={IntroGif} alt="" onLoad={() => setLoading(false)} />
                            </div>
                        </FadeOutWithTimer>
                    )} */}
                    {/* home gif end */}

                    {/* home slider */}
                    <div className="intro text-center" id="Home_Intro">
                        {/* FIXME: dots are not clickable have to fix that */}
                        <div className="home_slider_wrapper">
                            <Carousel
                                // animationHandler="fade"
                                showThumbs={false}
                                showStatus={false}
                                autoPlay={true}
                                infiniteLoop={true}
                                interval={6000}
                                showArrows={true}
                                showIndicators={false}
                                centerMode={true}
                            >
                                {HomeIntroSlider.map((d) => {
                                    return (
                                        <div className="home_slider" key={d.id}>
                                            <img alt={d.id} onLoad={() => setLoading(false)} src={d.img} />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </div>
                    </div>
                    {/* home slider end */}
                </>
            ) : (
                // mobile view
                <div className="mobile-intro">
                    <div className="intro text-center" id="Home_Intro">
                        <div className="headline-Wrapper-mobile">
                            <div className="text">
                                <div className="mobile-logo">
                                    <img onLoad={() => setLoading(false)} src={main__logo} alt="logo" />
                                </div>
                                <h1>
                                    LET US DESIGN
                                    <br />
                                    THE FUTURE
                                </h1>
                                <p>
                                    Globally the First Design brand aspiring to be the one-stop solution to all your worries. We aim to provide every
                                    single design service under one roof!
                                </p>
                            </div>
                        </div>
                        <div className="home_slider_wrapper_mobile">
                            <Carousel showThumbs={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={3000} showArrows={false}>
                                {HomeIntroSlider.map((d) => {
                                    return (
                                        <div className="home_slider" key={d.id}>
                                            <img alt={d.id} onLoad={() => setLoading(false)} src={d.img} />
                                        </div>
                                    );
                                })}
                            </Carousel>
                        </div>
                        <div className="home_social_wrapper_mobile">
                            <div className="contact">
                                <h5>CONTACT WITH US</h5>
                                <div className="phone">
                                    <a href="tel:913335573117">
                                        <i className="mr-2">
                                            <FaPhoneAlt />
                                        </i>
                                        +91&nbsp;33&nbsp;3557&nbsp;3117
                                    </a>
                                </div>
                                <div className="mail">
                                    <a href="mailto:info@vbridgemegacorp.com">
                                        <i className="mr-2">
                                            <MdEmail size={14} />
                                        </i>
                                        info@vbridgemegacorp.com
                                    </a>
                                </div>

                                <div className="social">
                                    <div className="mr-4">
                                        <a style={{ color: "#fff" }} href="https://www.facebook.com/abridge2k17/" target="_blank" rel="noreferrer">
                                            <ImFacebook2 size={15} />
                                        </a>
                                    </div>
                                    <div className="mr-4">
                                        <a
                                            style={{ color: "#fff" }}
                                            href="https://www.instagram.com/vbridgemegacorp/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <AiFillInstagram size={20} />
                                        </a>
                                    </div>
                                    <div className="mr-4">
                                        <a
                                            style={{ color: "#fff" }}
                                            href="https://in.linkedin.com/company/vbridge2k17"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <ImLinkedin size={15} />
                                        </a>
                                    </div>
                                    <div className="mr-4">
                                        <a
                                            style={{ color: "#fff" }}
                                            href="https://www.youtube.com/channel/UCWkj9y0Akz_bHiCWMebzyWA/videos"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <IoLogoYoutube size={20} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default HomeIntro;
