import React from "react";
import { AboutTeam, FloatingSocial, Wrapper } from "../components";
import { Footer, Header } from "../layout";

const Team = () => {
    return (
        <Wrapper id="team" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}
            <AboutTeam />
            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}
            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Team;
