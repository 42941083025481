import {
    home_slider_1,
    home_slider_2,
    home_slider_3,
    home_slider_4,
    home_slider_5,
    home_slider_6,
    home_slider_7,
    home_slider_8,
    home_slider_9,
    home_slider_10,
    home_slider_11,
    home_slider_12,
} from "../helpers/Images";

// -------------------------------------------
// home data
// -------------------------------------------

export const HomeIntroSlider = [
    { id: "intro_slider_1", img: home_slider_1 },
    { id: "intro_slider_3", img: home_slider_2 },
    { id: "intro_slider_6", img: home_slider_6 },
    { id: "intro_slider_7", img: home_slider_7 },
    { id: "intro_slider_2", img: home_slider_3 },
    { id: "intro_slider_8", img: home_slider_8 },
    { id: "intro_slider_4", img: home_slider_4 },
    { id: "intro_slider_9", img: home_slider_9 },
    { id: "intro_slider_5", img: home_slider_10 },
    { id: "intro_slider_5", img: home_slider_5 },
    { id: "intro_slider_5", img: home_slider_11 },
    { id: "intro_slider_5", img: home_slider_12 },
];
export const HomeAwardsData = [
    {
        id: "award1",
        title: "LONDON DESIGN BIENNALE",
        year: "2021",
        place: "BIOPHILLIC & SUSTAINABLE ARCHITECTURE CATEGORY",
        info: "ONE OF THE YOUNGEST ARCHITECTS TO REPRESENT INDIA & RECEIVE A RECOGNITION AT THE BIENNALE",
        org: "JAUGRAM",
    },
    {
        id: "award2",
        title: "THE NEXT ARCHITECT AWARD",
        year: "2020",
        place: "ARCHITECTURE FOR THE COMMUNITY",
        info: "UNIQUE RELATABLE LINES THAT AFFECTED SEVERAL LIVES DURING COVID THROUGH SOCIAL DISTANCING",
        org: "STREET ART",
    },
    {
        id: "award3",
        title: "WORLD ARCHITECTURE COMMUNITY",
        year: "2020",
        place: "SUSTAINABLE VILLAGE DEVELOPMENT PROJECT",
        info: "AFFECTED THOUSANDS OF RURAL LIFE THROUGH THIS PROJECT",
        org: "JAUGRAM",
    },
    {
        id: "award4",
        title: "WORLD ARCHITECTURE COMMUNITY",
        year: "2020",
        place: "RURAL DEVELOPMENT & DESIGN",
        info: "IMMENSELY HELPED TO SPREAD AWARENESS DURING COVID",
        org: "STREET ART",
    },
];
