import React from "react";

import { ContactForm, FloatingSocial, Iframe, Wrapper } from "../components";
import { Footer, Header } from "../layout";
import { AiFillInstagram } from "react-icons/ai";
import { ImFacebook2, ImLinkedin } from "react-icons/im";
import { IoLogoYoutube } from "react-icons/io";
import { useIsSmall } from "../helpers/Hooks";
const Contact = () => {
    const isSmall = useIsSmall();
    return (
        <Wrapper id="contact" pageKey="vbridge__contact__page">
            {/* header */}
            <Header />
            {/* header end */}
            {!isSmall ? (
                <div className="content">
                    <div className="container-fluid">
                        <h2 className="mt-4 text-center">CONTACT US</h2>
                        <div className="row">
                            <div className="col-lg-6 ">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h5 className="mt-5">Kolkata, West Bengal</h5>
                                        <p className="my-2">Kasba</p>
                                        {/* <p className="my-2">
                                                    <i className="mr-3">
                                                        <IoLocation />
                                                    </i>
                                                    Corporate Address - GM14, Rajdanga Main Road, Kasba E.K.T, Kolkata 700107
                                                </p> */}
                                        {/* Google map */}
                                        <Iframe htmlCode="<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.7879699213086!2d88.39180746537045!3d22.51213704085971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277151a625c37%3A0x658a70bd4b6cc2ee!2sVBRIDGE!5e0!3m2!1sen!2sin!4v1664284093877!5m2!1sen!2sin' width='100%' height='200' style='border:0;' allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>" />
                                        {/* Google map end */}
                                    </div>
                                    <div className="col-lg-6">
                                        <h5 className="mt-5">Mumbai, Maharashtra</h5>
                                        <p className="my-2">Andheri West</p>
                                        {/* Google map */}
                                        <Iframe htmlCode="<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.3419908311744!2d72.83015451533642!3d19.136501655039833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b73f095ce8ed%3A0x6bb63e63bffbda45!2sKuber%20Complex!5e0!3m2!1sen!2sin!4v1664450515309!5m2!1sen!2sin' width='100%' height='200' style='border:0;' allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>" />
                                        {/* Google map end */}
                                    </div>
                                    <div className="col-lg-12">
                                        <h5 className="mt-4">Malaysia</h5>
                                        <p className="my-2">KuaLa Lumpur</p>
                                        {/* Google map */}
                                        <Iframe htmlCode="<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.3848096587285!2d101.56746031475694!3d2.99054699782204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x34b79a4636f370e6!2zMsKwNTknMjYuMCJOIDEwMcKwMzQnMTAuNyJF!5e0!3m2!1sen!2sin!4v1664526708298!5m2!1sen!2sin' width='100%' height='200' style='border:0;' allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>" />
                                        {/* Google map end */}
                                    </div>
                                    <div className="col-lg-12">
                                        <h5 className="mt-4">Social Media Handles</h5>

                                        <a style={{ color: "#fff", marginRight: "20px" }} href="https://www.facebook.com/abridge2k17/">
                                            <ImFacebook2 size={30} />
                                        </a>

                                        <a style={{ color: "#fff", marginRight: "20px" }} href="https://www.instagram.com/vbridgemegacorp/">
                                            <AiFillInstagram size={40} />
                                        </a>

                                        <a style={{ color: "#fff", marginRight: "20px" }} href="https://in.linkedin.com/company/vbridge2k17">
                                            <ImLinkedin size={30} />
                                        </a>

                                        <a
                                            style={{ color: "#fff", marginRight: "20px" }}
                                            href="https://www.youtube.com/channel/UCWkj9y0Akz_bHiCWMebzyWA/videos"
                                        >
                                            <IoLogoYoutube size={40} />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 my-5">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="content">
                    <div className="container-fluid">
                        <h2 className="mt-4 text-center">CONTACT US</h2>
                        <div className="row">
                            <div className="col-lg-6 my-5">
                                <ContactForm />
                            </div>
                            <div className="col-lg-6 ">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <h5 className="mt-5">Kolkata, West Bengal</h5>
                                        <p className="my-2">Kasba</p>
                                        {/* <p className="my-2">
                                                    <i className="mr-3">
                                                        <IoLocation />
                                                    </i>
                                                    Corporate Address - GM14, Rajdanga Main Road, Kasba E.K.T, Kolkata 700107
                                                </p> */}
                                        {/* Google map */}
                                        <Iframe htmlCode="<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3685.7879699213086!2d88.39180746537045!3d22.51213704085971!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a0277151a625c37%3A0x658a70bd4b6cc2ee!2sVBRIDGE!5e0!3m2!1sen!2sin!4v1664284093877!5m2!1sen!2sin' width='100%' height='200' style='border:0;' allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>" />
                                        {/* Google map end */}
                                    </div>
                                    <div className="col-lg-6">
                                        <h5 className="mt-5">Mumbai, Maharashtra</h5>
                                        <p className="my-2">Andheri West</p>
                                        {/* Google map */}
                                        <Iframe htmlCode="<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.3419908311744!2d72.83015451533642!3d19.136501655039833!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b73f095ce8ed%3A0x6bb63e63bffbda45!2sKuber%20Complex!5e0!3m2!1sen!2sin!4v1664450515309!5m2!1sen!2sin' width='100%' height='200' style='border:0;' allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>" />
                                        {/* Google map end */}
                                    </div>
                                    <div className="col-lg-12">
                                        <h5 className="mt-4">Malaysia</h5>
                                        <p className="my-2">KuaLa Lumpur</p>
                                        {/* Google map */}
                                        <Iframe htmlCode="<iframe src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.3848096587285!2d101.56746031475694!3d2.99054699782204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x34b79a4636f370e6!2zMsKwNTknMjYuMCJOIDEwMcKwMzQnMTAuNyJF!5e0!3m2!1sen!2sin!4v1664526708298!5m2!1sen!2sin' width='100%' height='200' style='border:0;' allowfullscreen='' loading='lazy' referrerpolicy='no-referrer-when-downgrade'></iframe>" />
                                        {/* Google map end */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Contact;
