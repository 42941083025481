import {
    testimonial__thumb_debasis_sen,
    testimonial__thumb_Meghdut_Roy_Chowdhury,
    testimonial__thumb_Bhaichung_Bhutia,
    testimonial__thumb_Ajoy_Kumar_Ray,
    testimonial__thumb_MR_KINJAL_BHATTACHARYA,
    testimonial__thumb_MR_HARSHVARDHAN_NEOTIA,
    testimonial__thumb_MR_PRAKHAR_AGGARWAL,
    testimonial__thumb_MR_AMIT_KUMAR_SANTRA,
    testimonial__thumb_Thirilogachandran_Shanmugasundaram,
} from "../helpers/Images";
// home page testimonials
export const TestiData = [
    {
        id: "testi1",
        author_name: "MR. DEBASHIS SEN",
        author_role: "MD, HIDCO | CHAIRMAN, NDITA",
        author_thumbnail: testimonial__thumb_debasis_sen,
        testi: "Ayan & Soumyodeep are absolutely fantastic. I think the members of VBRIDGE are very talented & innovative. Some of their projects in Newtown have received great appreciation from all concerned. This shows to prove that, conventional engineering projects should try to involve startups for fresh ideas & innovative designs.",
    },
    {
        id: "testi2",
        author_name: "MR. HARSHVARDHAN NEOTIA",
        author_role: "PADMASHRI | chairman, Ambuja Neotia Group",
        author_thumbnail: testimonial__thumb_MR_HARSHVARDHAN_NEOTIA,
        testi: "I think technologies that can help reduce the carbon footprint & make the construction faster, better - I think I can see some of that effort being made here. So congratulations and good wishes to all your partners who are also supplying very interesting products. I will definitely ask my team to explore.",
    },
    {
        id: "testi3",
        author_name: "dr. Ajoy Kumar Ray",
        author_role: "PADMASHRI | EX-director, IIEST, Shibpur",
        author_thumbnail: testimonial__thumb_Ajoy_Kumar_Ray,
        testi: "As the Director of IIEST Shibpur during their college days, both Ayan & Soumyodeep were extremely active & always wanted to excel. A lot of innovative projects were done by them. As their professor, I feel proud to say that not only in Bengal, but also in India, they are one of the best duo in Architecture. I am sure they will immensely contribute to the future world.",
    },
    {
        id: "testi4",
        author_name: "Mr. BHAICHUNG BHUTIA",
        author_role: "PADMASHRI | ARJUNA AWARD, FOOTBALL | EX-INDIAN CAPTAIN, FOOTBALL",
        author_thumbnail: testimonial__thumb_Bhaichung_Bhutia,
        testi: "It is wonderful to bring Ayan & Soumyodeep from VBRIDGE to Sikkim in village Tinkitam. We have been looking and discussing some of the project & hopefully in future we definitely gonna work and make sure that we do some good quality projects.",
    },
    {
        id: "testi5",
        author_name: "MR. MEGHDUT ROYCHOWDHURY",
        author_role: "EXECUTIVE DIRECTOR & CHIEF INNOVATION OFFICER, TECHNO INDIA GROUP",
        author_thumbnail: testimonial__thumb_Meghdut_Roy_Chowdhury,
        testi: "When I met them, I realized that there was not one iota of doubt that these were some of the brightest young Architects that I have ever met. They are not only Architects but they are thinking how the entire world functions around them. Given a challenge in design, they are looking at every single problem & perspective of the customer. I was very impressed with their work.",
    },

    {
        id: "testi6",
        author_name: "MR. PRAKHAR AGGARWAL",
        author_role: "Business Head Nest-In at Tata Steel",
        author_thumbnail: testimonial__thumb_MR_PRAKHAR_AGGARWAL,
        testi: "We are very proud of being associated with VBRIDGE. With you we have been able to come out with library for Manipal project, quite a few COVID hospitals, canteens, studio apartments & many projects lined up.We are happy to have you as our collaboration team & to see you grow. I am seeing your team as a top Architect in the Indian landscape who brings something new everyday.",
    },
    {
        id: "testi7",
        author_name: "MR. KINJAL BHATTACHARYA",
        author_role: "Founder, MD & Group CEO- E365 India Pvt. Ltd.",
        author_thumbnail: testimonial__thumb_MR_KINJAL_BHATTACHARYA,
        testi: "Wanted to make them experiment with space design in live events. We collaborated with VBRIDGE on projects. I have a small video which has been just launched by Mr. Shahrukh Khan (actor), which talks about design, and whatever I ended up doing with this as a producer for the TV shoot, I learnt a lot of thing from these two young men, Ayan & Soumyodeep.",
    },

    {
        id: "testi8",
        author_name: "MR. AMIT KUMAR SANTRA",
        author_role: "Head Business Development (TATA Structura) at TATA Steel Ltd.",
        author_thumbnail: testimonial__thumb_MR_AMIT_KUMAR_SANTRA,
        testi: "Congratulations to Ayan and Soumyodeep for their achievements in past few years. The kind of innovation they are carrying, they should grow. They have started using steel in several projects and should grow along with us. We are always there for you.",
    },
    {
        id: "testi9",
        author_name: "aR. Thirilogachandran Shanmugasundaram",
        author_role: "Principal, TLC Architect | Honorary Treasurer , PAM ex-Chairman , ARCASIA | Chartered Member of RIBA | sec. gen.,earoph",
        author_thumbnail: testimonial__thumb_Thirilogachandran_Shanmugasundaram,
        testi: "I have known Ayan & Soumyodeep since a long time. When they came Malaysia to do internship, I have seen them grow, worked hard and set up their own office, VBRIDGE. They are very hard working & enterprising. Now they are also collaborating with us & together we are looking forward to work in India.",
    },
];
