import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Move } from "../../animation";
import {
    home__SponsorImg1,
    home__SponsorImg2,
    home__SponsorImg3,
    home__SponsorImg4,
    home__SponsorImg5,
    home__SponsorImg6,
} from "../../../helpers/Images";
import { ClientsData } from "../../../data/ClientsData";

const Sponsor = () => {
    // const navigate = useHref();
    const navigateUrl = (url) => {
        let element = document.createElement("a");

        if (url.startsWith("http://") || url.startsWith("https://")) {
            element.href = url;
        } else {
            element.href = "http://" + url;
        }

        element.click();
    };
    ClientsData.map((cd) => {
        console.log(cd.img_name);
    });
    return (
        <div className="clients-wrapper">
            <div className="container">
                {/* ------------------------------------------- */}
                {/* clients */}
                {/* ------------------------------------------- */}
                <div className="row">
                    {ClientsData.map((cd) => (
                        <div className="col-lg-3 item-padding" onClick={() => window.open(cd.link, "_blank")}>
                            <div className="client">
                                <div className="client-img">
                                    <img src={cd.img_name} alt="client" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* ------------------------------------------- */}
                {/* clients end */}
                {/* ------------------------------------------- */}
            </div>
        </div>
    );
};

export default Sponsor;
