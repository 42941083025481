import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const Scroll = ({ children, uqkey }) => {
    return (
        <AnimatePresence>
            <motion.div
                initial={{ y: 300, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                exit={{ y: -300, opacity: 0 }}
                viewport={{ once: true, amount: 0.3 }}
                transition={{ duration: 0.7 }}
                key={uqkey}
            >
                {children}
            </motion.div>
        </AnimatePresence>
    );
};

export default Scroll;
