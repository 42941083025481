import React, { useState } from "react";
import CLoader from "../../base/loader/CLoader";

// social project data
import { Community_Data } from "../../../data/communityData";

const CommunityWorks = () => {
    const SW_Names = [];
    const SW_Id = [];

    Community_Data.forEach((d) => {
        SW_Names.push(d.name);
        SW_Id.push(d.id);
    });

    const [loading, setLoading] = useState(true);
    return (
        <div className="container">
            <>
                {loading && <CLoader />}

                <h2 className="text-heading text-center">COMMUNITY SOCIAL RESPONSIBILITIES, TEAM ACTIVITIES & MORE</h2>

                {/* Community works content */}
                <div className="community-works mt-5">
                    {/* <div className="row">
                        {Community_Data.map((d, i) => {
                            <div className="col-lg-6 item-padding">
                                <div className="work" onClick={() => window.open(d.link, "_blank")}>
                                    <div className="work-img">
                                        <img src={d.thumbnail} onLoad={() => setLoading(false)} alt="" />
                                    </div>
                                    <div className="work-info">
                                        <h5 className="text-subheading">{d.name}</h5>
                                    </div>
                                </div>
                            </div>;
                        })}
                    </div> */}
                    <div className="row">
                        {Community_Data.map((d, i) => {
                            return (
                                <div className="col-lg-4 item-padding">
                                    <div className="work" onClick={() => window.open(d.link, "_blank")}>
                                        <div className="work-img">
                                            <img src={d.thumbnail} onLoad={() => setLoading(false)} alt="" />
                                        </div>
                                        <div className="work-info">
                                            <h5 className="text-subheading">{d.name}</h5>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* Community works content end*/}
            </>
        </div>
    );
};

export default CommunityWorks;
