import React from "react";

// home page components
import { FloatingScroll, FloatingSocial, HomeIntro, MetricsCounter, Wrapper } from "../components";
import HomeAwards from "../components/home/home-awards/HomeAwards";

// layout components
import { Footer, Header } from "../layout";

const Home = () => {
    return (
        <Wrapper id="home" pageKey="vbridge__home__page">
            {/* header */}
            <Header />
            {/* header end */}

            {/* intro */}
            <HomeIntro />
            {/* intro end */}

            {/* Metrics Counter  */}
            <MetricsCounter />
            {/* Metrics Counter end */}

            {/* awards timeline */}
            <HomeAwards />
            {/* awards timeline end */}

            {/* floating scroll */}
            <FloatingScroll />
            {/* floating scroll end */}

            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Home;
