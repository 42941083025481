import React from "react";
import { Link } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { MdLocationOn } from "react-icons/md";
import { BiRightArrow } from "react-icons/bi";
/* ------------------------------------------- */
/* footer images */
/* ------------------------------------------- */
import logo from "../../assets/images/logo.png";
import footerLine from "../../assets/images/footer-line.svg";

import { useIsSmall } from "../../helpers/Hooks";
import { main__logo } from "../../helpers/Images";

const Footer = () => {
    const isSmall = useIsSmall();

    return (
        <footer>
            <img src={footerLine} alt="" />
            <div className="container pt-3">
                <div className="footer">
                    <div className="copyright">
                        <div className="logo">
                            <img src={main__logo} alt="" />
                        </div>
                        <div className="contact">
                            <div className="phone">
                                <a href="tel:913335573117">
                                    <i className="mr-3">
                                        <FaPhoneAlt />
                                    </i>
                                    +91&nbsp;33&nbsp;3557&nbsp;3117
                                </a>
                            </div>
                            <div className="mail">
                                <a href="mailto:info@vbridgemegacorp.com">
                                    <i>
                                        <MdEmail size={14} />
                                    </i>
                                    info@vbridgemegacorp.com
                                </a>
                            </div>
                        </div>
                        <p>
                            Vbridge © 2022. All rights reserved | Developed By{" "}
                            <a href="https://www.alienity.com/" target="_blank" rel="noreferrer">
                                Alienity Technologies
                            </a>
                        </p>
                    </div>
                    {!isSmall ? (
                        <>
                            <div className="menu">
                                {/* <Link to="/about">
                                    <p>
                                        <i>
                                            <BiRightArrow />{" "}
                                        </i>
                                        About Us
                                    </p>
                                </Link> */}

                                <Link to="/projects">
                                    <p>
                                        <i>
                                            <BiRightArrow />
                                        </i>
                                        Projects
                                    </p>
                                </Link>

                                <Link to="/contact">
                                    <p>
                                        <i>
                                            <BiRightArrow />
                                        </i>
                                        Contact us
                                    </p>
                                </Link>

                                <Link to="/news">
                                    <p>
                                        <i>
                                            <BiRightArrow />
                                        </i>
                                        News
                                    </p>
                                </Link>
                                {/* <Link to="/terms">
                                    <p>
                                        <i>
                                            <BiRightArrow />
                                        </i>
                                        Terms
                                    </p>
                                </Link> */}
                            </div>
                            <div className="location">
                                <h5>Corporate Headquarter -</h5>
                                <p>
                                    <i className="mr-2">
                                        <MdLocationOn />
                                    </i>
                                    GM14, Rajdanga Main Road, Kasba E.K.T, Kolkata 700107
                                </p>
                                <h5>Registered HQ Address -</h5>
                                <p>
                                    <i className="mr-2">
                                        <MdLocationOn />
                                    </i>
                                    184/9, B.B.D Road, P.O. Hindmotor, Ward No. 07, Uttarpara, Hooghly, PIN – 712233
                                </p>
                                {/* Google map */}
                                {/* <Iframe
                                    htmlCode="<iframe
                                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14723.077172943837!2d88.32972330594289!3d22.699630749121784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f89ca5c0b0ae55%3A0xca6f0520737c7b85!2sSAMANTA%20NURSERY!5e0!3m2!1sen!2sin!4v1653998001692!5m2!1sen!2sin'
                                width='100%'
                                height='150'
                                style='border: 0'
                                allowfullscreen=''
                                loading='lazy'
                                referrerpolicy='no-referrer-when-downgrade'
                            ></iframe>"
                                /> */}
                                {/* Google map end */}
                            </div>{" "}
                        </>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </footer>
    );
};

export default Footer;
