import React from "react";

const Sustainabilities = ({ sustainPercentage, sustainInfo }) => {
    return (
        <div className="sustain">
            <h5 className="text-heading">{sustainPercentage}</h5>
            <p className="text-paragraph">{sustainInfo}</p>
        </div>
    );
};

export default Sustainabilities;
