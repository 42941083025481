import React, { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const FloatingScroll = () => {
    const [windowPosition, setWindowPosition] = useState(0);
    const [showTopButton, setShowTopButton] = useState(false);
    useState();

    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 200) {
                setShowTopButton(true);
            } else {
                setShowTopButton(false);
            }
        });
    }, []);

    const windowMoveHandler = () => {
        if (showTopButton) {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        } else {
            window.scrollTo({
                top: 700,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="floating-scroll" onClick={windowMoveHandler}>
            {showTopButton ? <FaAngleUp /> : <FaAngleDown />}
        </div>
    );
};

export default FloatingScroll;
