import React, { useState } from "react";
import { Team_Core_Data, Team_partners_Data } from "../../../data/TeamData";
import CLoader from "../../base/loader/CLoader";
import { useIsSmall } from "../../../helpers/Hooks";

const AboutTeam = () => {
    const [loading, setLoading] = useState(true);
    //for small screen
    const isSmall = useIsSmall();

    return (
        <>
            {loading && <CLoader />}
            <div className="teams">
                <div className="container">
                    <h2 className="text-center">VBRIDGE SQUAD</h2>

                    {/* The Core Team */}
                    <h5 className="text-center">The Core Team</h5>
                    <div className="row mt-5">
                        {/* 1st row of core team */}
                        {/* co-founder  */}
                        <div className="row">
                            {Team_Core_Data.filter((cf) => cf.main_role === "Co-Founder").map((d, i) => {
                                return (
                                    <div className="col-lg-12 item-padding">
                                        <div className="co-founder">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <img onLoad={() => setLoading(false)} src={d.img} alt="" />
                                                </div>
                                                <div className="col-lg-8">
                                                    <div className="co-founder-info pt-5">
                                                        <h4 className="text-heading text-uppercase">{d.name}</h4>
                                                        <p className="text-paragraph">
                                                            {d.main_role} {d.extra_role === "" ? "" : "|"} {d.extra_role}
                                                        </p>
                                                        <p className="text-paragraph">{d.info}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {/* co-founder end */}
                            {/* core team except co-founders */}
                            {Team_Core_Data.filter((cf) => cf.main_role !== "Co-Founder").map((d, i) => {
                                return (
                                    <div className="col-lg-4 item-padding">
                                        <div className="member other-member">
                                            <img onLoad={() => setLoading(false)} src={d.img} alt="" />
                                            <div className="member-info">
                                                <h5 className="text-uppercase">{d.name}</h5>
                                                <p>
                                                    {d.main_role} {d.extra_role === "" ? "" : "|"} {d.extra_role}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            {/* core team except co-founders end */}
                        </div>
                    </div>
                    {/* The Core Team end*/}

                    {/* Ecosystem partners Team */}
                    <h3 className="text-center text-uppercase mt-5">Vbridge Associates</h3>
                    {/* <h5 className="text-center">We are incomplete without you!</h5> */}
                    <div className="row mt-5">
                        {Team_partners_Data.map((d) => {
                            return (
                                <div className="col-lg-4 item-padding">
                                    <div className="eco-member">
                                        {/* <img onLoad={() => setLoading(false)}  src={about__teamMember6} alt="" /> */}
                                        <div className="member-info">
                                            <h5 className="text-uppercase text-heading">{d.name}</h5>
                                            <p className="text-paragraph">{d.companey}</p>
                                            <p className="text-paragraph">{d.main_role}</p>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {/* Ecosystem partners Team end*/}
            </div>
        </>
    );
};

export default AboutTeam;
