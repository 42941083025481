import {
    project_full_ARUN_KRIPAL_RESIDENCE_1,
    project_full_ARUN_KRIPAL_RESIDENCE_2,
    project_full_ARUN_KRIPAL_RESIDENCE_3,
    project_full_BACHELOR_ACCOMODATION_ONGC_1,
    project_full_BACHELOR_ACCOMODATION_ONGC_2,
    project_full_BOHATA_3BHK_1,
    project_full_BOHATA_3BHK_2,
    project_full_BOHATA_3BHK_3,
    project_full_BOHATA_3BHK_4,
    project_full_BOHATA_6BHK_1,
    project_full_BOHATA_6BHK_2,
    project_full_BOHATA_6BHK_3,
    project_full_BOHATA_6BHK_4,
    project_full_BOHATA_6BHK_5,
    project_full_BOHATA_6BHK_6,
    project_full_CIIIT_MANIPUR_1,
    project_full_CIIIT_MANIPUR_2,
    project_full_CRC_TARAPUR_1,
    project_full_CRC_TARAPUR_2,
    project_full_CRC_TARAPUR_3,
    project_full_CRECHE_FACILITY_1,
    project_full_CRECHE_FACILITY_2,
    project_full_CRECHE_FACILITY_3,
    project_full_CRECHE_FACILITY_4,
    project_full_GM_QSEB_1,
    project_full_GM_QSEB_2,
    project_full_GM_QSEB_3,
    project_full_HIGHRISE_JAMSHEDPUR_1,
    project_full_HIGHRISE_JAMSHEDPUR_10,
    project_full_HIGHRISE_JAMSHEDPUR_11,
    project_full_HIGHRISE_JAMSHEDPUR_12,
    project_full_HIGHRISE_JAMSHEDPUR_13,
    project_full_HIGHRISE_JAMSHEDPUR_14,
    project_full_HIGHRISE_JAMSHEDPUR_15,
    project_full_HIGHRISE_JAMSHEDPUR_16,
    project_full_HIGHRISE_JAMSHEDPUR_17,
    project_full_HIGHRISE_JAMSHEDPUR_18,
    project_full_HIGHRISE_JAMSHEDPUR_19,
    project_full_HIGHRISE_JAMSHEDPUR_2,
    project_full_HIGHRISE_JAMSHEDPUR_20,
    project_full_HIGHRISE_JAMSHEDPUR_3,
    project_full_HIGHRISE_JAMSHEDPUR_4,
    project_full_HIGHRISE_JAMSHEDPUR_5,
    project_full_HIGHRISE_JAMSHEDPUR_6,
    project_full_HIGHRISE_JAMSHEDPUR_7,
    project_full_HIGHRISE_JAMSHEDPUR_8,
    project_full_HIGHRISE_JAMSHEDPUR_9,
    project_full_JOYVILLE_APARTMENT_1,
    project_full_JOYVILLE_APARTMENT_2,
    project_full_JOYVILLE_APARTMENT_3,
    project_full_JOYVILLE_APARTMENT_4,
    project_full_JRD_TATA_GATE_1,
    project_full_JRD_TATA_GATE_2,
    project_full_JRD_TATA_GATE_3,
    project_full_JRD_TATA_GATE_4,
    project_full_JRD_TATA_GATE_5,
    project_full_JRD_TATA_GATE_6,
    project_full_MAHE_1,
    project_full_MAHE_2,
    project_full_MAHE_3,
    project_full_MAHE_4,
    project_full_MAHE_5,
    project_full_MAHE_6,
    project_full_MAHE_7,
    project_full_MILI_APARTMENT_1,
    project_full_MILI_APARTMENT_3,
    project_full_MILI_APARTMENT_4,
    project_full_NEELKAMAL_1,
    project_full_NEELKAMAL_2,
    project_full_NEELKAMAL_3,
    project_full_NIT_CAFETERIA_1,
    project_full_NIT_CAFETERIA_10,
    project_full_NIT_CAFETERIA_2,
    project_full_NIT_CAFETERIA_3,
    project_full_NIT_CAFETERIA_4,
    project_full_NIT_CAFETERIA_5,
    project_full_NIT_CAFETERIA_6,
    project_full_NIT_CAFETERIA_7,
    project_full_NIT_CAFETERIA_8,
    project_full_NIT_CAFETERIA_9,
    project_full_NIT_LANDSCAPE_1,
    project_full_NIT_LANDSCAPE_2,
    project_full_NIT_LANDSCAPE_3,
    project_full_NIT_LANDSCAPE_4,
    project_full_NOAMUNDI_HOSPITAL_1,
    project_full_NOAMUNDI_HOSPITAL_2,
    project_full_NOAMUNDI_HOSPITAL_3,
    project_full_NSCB_UNIVERSITY_1,
    project_full_NTPC_PORTA_1,
    project_full_NTPC_PORTA_2,
    project_full_NTPC_PORTA_3,
    project_full_NTPC_PORTA_4,
    project_full_PADMALYA_1,
    project_full_PADMALYA_2,
    project_full_PADMALYA_3,
    project_full_PADMALYA_4,
    project_full_PADMALYA_5,
    project_full_PEERLES_1_01,
    project_full_PEERLES_1_02,
    project_full_PEERLES_2_01,
    project_full_PEERLES_2_02,
    project_full_project_thumb_COMMENTRY_BOX_SCA_1,
    project_full_project_thumb_COMMENTRY_BOX_SCA_2,
    project_full_QUINTEX_1,
    project_full_QUINTEX_2,
    project_full_QUINTEX_3,
    project_full_QUINTEX_4,
    project_full_QUINTEX_5,
    project_full_QUINTEX_6,
    project_full_QUINTEX_7,
    project_full_QUINTEX_8,
    project_full_QUINTEX_9,
    project_full_SITE_OFFICE_AASTHA_1,
    project_full_SITE_OFFICE_AASTHA_2,
    project_full_SITE_OFFICE_AASTHA_3,
    project_full_SLDC_JAMSHEDPUR_1,
    project_full_SLDC_JAMSHEDPUR_2,
    project_full_SLDC_JAMSHEDPUR_3,
    project_full_SMART_MODULAR_TOILET_1,
    project_full_SMART_MODULAR_TOILET_10,
    project_full_SMART_MODULAR_TOILET_11,
    project_full_SMART_MODULAR_TOILET_12,
    project_full_SMART_MODULAR_TOILET_13,
    project_full_SMART_MODULAR_TOILET_14,
    project_full_SMART_MODULAR_TOILET_15,
    project_full_SMART_MODULAR_TOILET_16,
    project_full_SMART_MODULAR_TOILET_2,
    project_full_SMART_MODULAR_TOILET_3,
    project_full_SMART_MODULAR_TOILET_4,
    project_full_SMART_MODULAR_TOILET_5,
    project_full_SMART_MODULAR_TOILET_6,
    project_full_SMART_MODULAR_TOILET_7,
    project_full_SMART_MODULAR_TOILET_8,
    project_full_SMART_MODULAR_TOILET_9,
    project_full_SRIJAN_SOUTHWIND_1,
    project_full_SRIJAN_SOUTHWIND_2,
    project_full_SRIJAN_SOUTHWIND_3,
    project_full_STUDIO_APARTMENT_1,
    project_full_STUDIO_APARTMENT_2,
    project_full_STUDIO_APARTMENT_3,
    project_full_TUBE_MAKERS_CLUB_1,
    project_full_TUBE_MAKERS_CLUB_2,
    project_full_WEST_BOKARA_TOWNSHIP_1,
    project_full_WEST_BOKARA_TOWNSHIP_2,
    project_full_WEST_BOKARA_TOWNSHIP_3,
    project_full_WEST_BOKARA_TOWNSHIP_4,
    project_full_WEST_BOKARA_TOWNSHIP_5,
    project_full__DUPLEX_DHANBAD_1,
    project_full__DUPLEX_DHANBAD_2,
    project_full__DUPLEX_DHANBAD_3,
    project_thumb_ARUN_KRIPAL_RESIDENCE,
    project_thumb_BACHELOR_ACCOMODATION_ONGC,
    project_thumb_BOHATA_3BHK,
    project_thumb_BOHATA_6BHK,
    project_thumb_BPCL_IN_AND_OUT,
    project_thumb_CIIIT_MANIPUR,
    project_thumb_COMMENTRY_BOX_SCA,
    project_thumb_CRC_TARAPUR,
    project_thumb_CRECHE_FACILITY,
    project_thumb_DUPLEX_DHANBAD,
    project_thumb_GM_QSEB,
    project_thumb_HIGHRISE_JAMSHEDPUR,
    project_thumb_ILLUSION_ART,
    project_thumb_JOYVILLE_APARTMENTS,
    project_thumb_JRD_TATA_GATE,
    project_thumb_MAHE,
    project_thumb_MILI_APARTMENT,
    project_thumb_NEELKAMAL,
    project_thumb_NIT_CAFETERIA,
    project_thumb_NIT_LANDSCAPE,
    project_thumb_NOAMUNDI_HOSPITAL,
    project_thumb_NSCB_UNIVERSITY,
    project_thumb_NTPC_PORTA,
    project_thumb_PADMALAYA,
    project_thumb_PEERLEES_1,
    project_thumb_PEERLES_2,
    project_thumb_QUINTEX,
    project_thumb_SITE_OFFICE_AASTHA,
    project_thumb_SLDC_JAMSHEDPUR,
    project_thumb_SMART_MODULAR_TOILET,
    project_thumb_SRIJAN_SOUTHWIND,
    project_thumb_STUDIO_APARTMENT,
    project_thumb_TUBE_MAKERS_CLUB,
    project_thumb_WEST_BOKARA_TOWNSHIP,
    project__CREMATORIUM1,
    project__CREMATORIUM2,
    project__CREMATORIUM3,
    project__CREMATORIUM4,
    project__CREMATORIUM5,
    project__full_BPCL_IN_OUT_1,
    project__full_COVID_HOSPITAL_1,
    project__full_COVID_HOSPITAL_2,
    project__full_COVID_HOSPITAL_3,
    project__full_COVID_HOSPITAL_4,
    project__full_CREMATORIUM,
    project__full_ILLUSION_ART_RAILING,
    project__full_MOHALLA_CLINIC,
    project__full_NDITA_ICONIC_GATE,
    project__full_TSJ_COKE_PLANT_1,
    project__full_TSJ_COKE_PLANT_2,
    project__full_TSJ_COKE_PLANT_3,
    project__full_TSJ_COKE_PLANT_4,
    project__full_VEDANTA_TOWNSHIP,
    project__ILLUSION_ART_RAILING1,
    project__ILLUSION_ART_RAILING11,
    project__ILLUSION_ART_RAILING12,
    project__ILLUSION_ART_RAILING2,
    project__ILLUSION_ART_RAILING3,
    project__ILLUSION_ART_RAILING4,
    project__ILLUSION_ART_RAILING5,
    project__ILLUSION_ART_RAILING6,
    project__ILLUSION_ART_RAILING7,
    project__ILLUSION_ART_RAILING8,
    project__ILLUSION_ART_RAILING9,
    project__JAQUAR1,
    project__JAQUAR2,
    project__JAQUAR3,
    project__JAQUAR4,
    project__JAQUAR5,
    project__JAQUAR6,
    project__MOHALLA_CLINIC2,
    project__MOHALLA_CLINIC3,
    project__MOHALLA_CLINIC4,
    project__MOHALLA_CLINIC5,
    project__NDITA_ICONIC_GATE1,
    project__NDITA_ICONIC_GATE2,
    project__PARO_AIRPORT1,
    project__PARO_AIRPORT2,
    project__PARO_AIRPORT3,
    project__thumb_COVID_HOSPITAL,
    project__thumb_CREMATORIUM,
    project__thumb_JAQUAR_RETAIL_STORE,
    project__thumb_MOHALLA_CLINIC,
    project__thumb_NDITA_ICONIC_GATE,
    project__thumb_PARO_AIRPORT,
    project__thumb_TSJ_COKE_PLANT,
    project__thumb_VEDANTA_TOWNSHIP,
} from "../helpers/Images";

export const ProjectInteriorData = [
    {
        id: "int1",
        desc: "The Project has been visualized with the idea of having a personal boutique within own home. It has been thoroughly crafted using the client's tight brief in mind",
        name: " Mili Home Boutique, Kolkata",
        client: "PRIVATE",
        value: "10 LACS",
        area: 450,
        time: "3 MONTHS",
        design: "RESIDENTIAL",
        status: "COMPLETED",
        category: "INTERIOR",
        projectThumb: project_thumb_MILI_APARTMENT,
        full_images: [project_full_MILI_APARTMENT_1, project_full_MILI_APARTMENT_3, project_full_MILI_APARTMENT_4],
    },
    {
        id: "int2",
        desc: "Minimalist is the word used by the client. Low budget yet a decent interior is what the client was looking for. Completed execution within just one &amp; half month.",
        name: "Joy House, Howrah",
        client: "PRIVATE",
        value: "6 LACS",
        area: 1000,
        time: "2 MONTHS",
        design: "RESIDENTIAL",
        status: "COMPLETED",
        category: "INTERIOR",
        projectThumb: project_thumb_JOYVILLE_APARTMENTS,
        full_images: [
            project_full_JOYVILLE_APARTMENT_1,
            project_full_JOYVILLE_APARTMENT_2,
            project_full_JOYVILLE_APARTMENT_3,
            project_full_JOYVILLE_APARTMENT_4,
        ],
    },
    {
        id: "int3",
        desc: "Royal touch is what the name suggests. The Client brief was taken &amp; a five member team completed the design task within a week to convert into execution",
        name: "Bahota Residence, Cooch Behar",
        client: "PRIVATE",
        value: "32 LACS",
        area: 950,
        time: "NA",
        design: "RESIDENTIAL",
        status: "ONGOING",
        category: "INTERIOR",
        projectThumb: project_thumb_BOHATA_3BHK,
        full_images: [project_full_BOHATA_3BHK_1, project_full_BOHATA_3BHK_2, project_full_BOHATA_3BHK_3, project_full_BOHATA_3BHK_4],
    },
    {
        id: "int4",
        desc: "Keeping the Daily requirements in mind, this has been designed to keep the simplicty high. The Design has been done keep client's taste of colors in mind.",
        name: "Southwinds, Kolkata",
        client: "PRIVATE",
        value: "16 LACS",
        area: 1115,
        time: "NA",
        design: "RESIDENTIAL",
        status: "INCEPTION STAGE",
        category: "INTERIOR",
        projectThumb: project_thumb_SRIJAN_SOUTHWIND,
        full_images: [project_full_SRIJAN_SOUTHWIND_1, project_full_SRIJAN_SOUTHWIND_2, project_full_SRIJAN_SOUTHWIND_3],
    },
    {
        id: "int5",
        desc: "This design has a story where a newly wed couple required a personal touch for creating the best ambience. Designed and executed within a very short span of time.",
        name: "Padma Alay, Kolkata",
        client: "PRIVATE",
        value: "5.5 LACS",
        area: 750,
        time: "4 MONTHS",
        design: "RESIDENTIAL",
        status: "COMPLETED",
        category: "INTERIOR",
        projectThumb: project_thumb_PADMALAYA,
        full_images: [project_full_PADMALYA_1, project_full_PADMALYA_2, project_full_PADMALYA_3, project_full_PADMALYA_4, project_full_PADMALYA_5],
    },
    {
        id: "int6",
        desc: "Elegance &amp; Heritage was the brief from the Client. This combination was challenging &amp; unique in terms of Interior Design but successfully ongoing execution presently.",
        name: "Bahota Villa, Cooch Behar",
        client: "PRIVATE",
        value: "1.2 CRORE",
        area: 1765,
        time: "NA",
        design: "RESIDENTIAL",
        status: "ONGOING",
        category: "INTERIOR",
        projectThumb: project_thumb_BOHATA_6BHK,
        full_images: [
            project_full_BOHATA_6BHK_1,
            project_full_BOHATA_6BHK_2,
            project_full_BOHATA_6BHK_3,
            project_full_BOHATA_6BHK_4,
            project_full_BOHATA_6BHK_5,
            project_full_BOHATA_6BHK_6,
        ],
    },
    {
        id: "int7",
        desc: "Five labs of the college were converted into a Students Café within 2.5 months including execution. A team of 40 Members have worked to complete the job that has industrial look mixed with college vibrance in the design.",
        name: "NIT Students' Cafeteria",
        client: "JIS GROUP",
        value: "1.3 CRORES",
        area: "10,000 SQFT",
        time: "2.5 MONTHS",
        design: "INSTITUITIONAL",
        status: "COMPLETED",
        category: "INTERIOR",
        projectThumb: project_thumb_NIT_CAFETERIA,
        full_images: [
            project_full_NIT_CAFETERIA_1,
            project_full_NIT_CAFETERIA_2,
            project_full_NIT_CAFETERIA_3,
            project_full_NIT_CAFETERIA_4,
            project_full_NIT_CAFETERIA_5,
            project_full_NIT_CAFETERIA_6,
            project_full_NIT_CAFETERIA_7,
            project_full_NIT_CAFETERIA_8,
            project_full_NIT_CAFETERIA_9,
            project_full_NIT_CAFETERIA_10,
        ],
    },
    {
        id: "int8",
        desc: "The retail stores have been designed using the client briefs in mind. Jaquar has its own style and VBRIDGE worked to enhance the given parameters at each of its unique stores across eastern India as its Official Design Partner.",
        name: "Retail Store Outlet Designs, India",
        client: "JAQUAR PVT. LTD.",
        value: "40 LACS EACH STORE",
        area: 1250,
        time: "2 MONTHS PER STORE",
        design: "COMMERCIAL",
        status: "COMPLETED",
        category: "INTERIOR",
        projectThumb: project__thumb_JAQUAR_RETAIL_STORE,
        full_images: [project__JAQUAR1, project__JAQUAR2, project__JAQUAR3, project__JAQUAR4, project__JAQUAR5, project__JAQUAR6],
    },
    {
        id: "int9",
        desc: "The Office Interior was a Modern yet industrial look approach given the client's taste in mind. Vibrant colors with dark ceiling &amp; warm lights have created a very valuable space for the user group.",
        name: "Quintex Office, Kolkata",
        client: "QUINTEX SOFTWARE SOLUTION",
        value: "17 LACS",
        area: 1350,
        time: "3 MONTHS",
        design: "OFFICE",
        status: "COMPLETED",
        category: "INTERIOR",
        projectThumb: project_thumb_QUINTEX,
        full_images: [
            project_full_QUINTEX_1,
            project_full_QUINTEX_2,
            project_full_QUINTEX_3,
            project_full_QUINTEX_4,
            project_full_QUINTEX_5,
            project_full_QUINTEX_6,
            project_full_QUINTEX_7,
            project_full_QUINTEX_8,
            project_full_QUINTEX_9,
        ],
    },
    {
        id: "int10",
        desc: "The dusky interiors were seeked by the client given their designer brief. All along the project, smart technologies have been briefly utilised in the best way possible.",
        name: "Neel Kamal, Kolkata",
        client: "PRIVATE",
        value: "2.1 CRORES",
        area: "3025 SQFT",
        time: "NA",
        design: "RESIDENTIAL",
        status: "INCEPTION STAGE",
        category: "INTERIOR",
        projectThumb: project_thumb_NEELKAMAL,
        full_images: [project_full_NEELKAMAL_1, project_full_NEELKAMAL_2, project_full_NEELKAMAL_3],
    },

    // --------------------------------------
    // ARCHITECTURE project Data
    // --------------------------------------

    {
        id: "arti1",
        desc: "Hilly Area with a rainy location. Major principles of design included managing the design parameters with maximum climatic consideration for the location.",
        name: "Kirpal Residence",
        client: "TATA STEEL NESTIN",
        value: "2 CRORES",
        area: 2800,
        time: "3 MONTHS",
        design: "RESIDENTIAL",
        status: "COMPLETED",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_ARUN_KRIPAL_RESIDENCE,
        full_images: [project_full_ARUN_KRIPAL_RESIDENCE_1, project_full_ARUN_KRIPAL_RESIDENCE_2, project_full_ARUN_KRIPAL_RESIDENCE_3],
    },
    // {
    //     id: "arti2",
    //     desc: "Considering the challenging lifestyle of Mines in the Eastern India, the design has a touch of its robustness yet with a touch of vibrance to eliminate the monotone.",
    //     name: "Noamundi Accommodation",
    //     client: "TATA STEEL NESTIN",
    //     value: "10.7 CRORES",
    //     area: 37900,
    //     time: "NA",
    //     design: "RESIDENTIAL",
    //     status: "ONGOING",
    //     category: "ARCHITECTURE",
    // },
    {
        id: "arti3",
        desc: "A very challenging project within a very short period was delivered to the client. 450+ retail outlets have been executed across the country.",
        name: "BPCL IN and OUT Store",
        client: "TATA STEEL NESTIN",
        value: "22 LACS",
        area: 400,
        time: "1.5 MONTHS",
        design: "COMMERCIAL",
        status: "COMPLETED",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_BPCL_IN_AND_OUT,
        full_images: [project__full_BPCL_IN_OUT_1],
    },
    {
        id: "arti4",
        desc: "The Plaza found vibrance when hue of several colors were used keeping the youth movement in mind. Stamp Concretes with green within plays a very crucial role in making this look impactful.",
        name: "Narula Students' Plaza",
        client: "JIS GROUP",
        value: "3.4 CRORES",
        area: "1 ACRE",
        time: "3 MONTHS",
        design: "INSTITUITIONAL",
        status: "COMPLETED",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_NIT_LANDSCAPE,
        full_images: [project_full_NIT_LANDSCAPE_1, project_full_NIT_LANDSCAPE_2, project_full_NIT_LANDSCAPE_3, project_full_NIT_LANDSCAPE_4],
    },
    {
        id: "arti5",
        desc: "The highly industrial institute would have combination of workshops along with classes. Foundation stone already laid by the CM of Manipur, this project is one big life affecting proposal for the rural areas of the North East India.",
        name: "CIIIT Institute , Manipur",
        client: "TATA STEEL NESTIN",
        value: "6.1 CRORE",
        area: 18650,
        time: "NA",
        design: "INSTITUITIONAL",
        status: "ONGOING",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_CIIIT_MANIPUR,
        full_images: [project_full_CIIIT_MANIPUR_1, project_full_CIIIT_MANIPUR_2],
    },
    {
        id: "arti6",
        desc: "The Mass Housing brief was to create unique skyline all along the Project Horizon. Internal spaces were filled with greenery &amp; for users to experience community spaces better.",
        name: "Dhanbad City Towers",
        client: "AASTHA GROUP",
        value: 400000000,
        area: 200000,
        time: "NA",
        design: "RESIDENTIAL",
        status: "INCEPTION STAGE",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_DUPLEX_DHANBAD,
        full_images: [project_full__DUPLEX_DHANBAD_1, project_full__DUPLEX_DHANBAD_2, project_full__DUPLEX_DHANBAD_3],
    },
    {
        id: "arti7",
        desc: "GM Office within the mines was really challenging yet pleasing considering the nature that the Team could find surrounding the entire project. No bricks have been using above plinth level for creating this unique office space within West Bokaro Mines.",
        name: "GM Office, QSEB Mines",
        client: "TATA STEEL NESTIN",
        value: 11175000,
        area: 3725,
        time: "2 MONTHS",
        design: "OFFICE",
        status: "COMPLETED",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_GM_QSEB,
        full_images: [project_full_GM_QSEB_1, project_full_GM_QSEB_2, project_full_GM_QSEB_3],
    },
    {
        id: "arti8",
        desc: "The Highrise Housing Project was uniquely designed keeping its user experience in mind. Right infront of the Subarnarekha river, this project finds itself a unique green quotient too.",
        name: "Subarna Heights, Jamshedpur",
        client: "AASTHA GROUP",
        value: 6300000000,
        area: 2100000,
        time: "NA",
        design: "RESIDENTIAL",
        status: "INCEPTION STAGE",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_HIGHRISE_JAMSHEDPUR,
        full_images: [
            project_full_HIGHRISE_JAMSHEDPUR_1,
            project_full_HIGHRISE_JAMSHEDPUR_2,
            project_full_HIGHRISE_JAMSHEDPUR_3,
            project_full_HIGHRISE_JAMSHEDPUR_4,
            project_full_HIGHRISE_JAMSHEDPUR_5,
            project_full_HIGHRISE_JAMSHEDPUR_6,
            project_full_HIGHRISE_JAMSHEDPUR_7,
            project_full_HIGHRISE_JAMSHEDPUR_8,
            project_full_HIGHRISE_JAMSHEDPUR_9,
            project_full_HIGHRISE_JAMSHEDPUR_10,
            project_full_HIGHRISE_JAMSHEDPUR_11,
            project_full_HIGHRISE_JAMSHEDPUR_12,
            project_full_HIGHRISE_JAMSHEDPUR_13,
            project_full_HIGHRISE_JAMSHEDPUR_14,
            project_full_HIGHRISE_JAMSHEDPUR_15,
            project_full_HIGHRISE_JAMSHEDPUR_16,
            project_full_HIGHRISE_JAMSHEDPUR_17,
            project_full_HIGHRISE_JAMSHEDPUR_18,
            project_full_HIGHRISE_JAMSHEDPUR_19,
            project_full_HIGHRISE_JAMSHEDPUR_20,
        ],
    },
    {
        id: "arti9",
        desc: "The staff of the Hospital specifically the nurses &amp; doctors required essential accommodation space nearby to the Hospital within the mines. Another challenging design where open spaces were intentionally created using court concept to enhance ventilation.",
        name: "Noamundi Hospital Staff Accommodation",
        client: "TATA STEEL NESTIN",
        value: 59700000,
        area: 19900,
        time: "NA",
        design: "HEALTHCARE",
        status: "ONGOING",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_NOAMUNDI_HOSPITAL,
        full_images: [project_full_NOAMUNDI_HOSPITAL_1, project_full_NOAMUNDI_HOSPITAL_2, project_full_NOAMUNDI_HOSPITAL_3],
    },
    {
        id: "arti10",
        desc: "The Library shapes up to keep as many treest as possible on the given site. In dense green fabric, this design opens up with a coutryard at the center for the students to read in natural light throughout the day and with a very minimal approach of colors.",
        name: "Manipal Group Academic Library, Jamshedpur",
        client: "TATA STEEL NESTIN",
        value: "17 CRORES",
        area: 55400,
        time: "7 MONTHS",
        design: "INSTITUITIONAL",
        status: "COMPLETED",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_MAHE,
        full_images: [
            project_full_MAHE_1,
            project_full_MAHE_2,
            project_full_MAHE_3,
            project_full_MAHE_4,
            project_full_MAHE_5,
            project_full_MAHE_6,
            project_full_MAHE_7,
        ],
    },
    {
        id: "arti11",
        desc: "With such clinics coming across the capital, Punjab Government also wanted more such clinics to come up in their state. Such were designed  keeping easy transport &amp; installation in mind.",
        name: "Moholla Clinic, India",
        client: "TATA STEEL NESTIN",
        value: "12 LACS",
        area: 1000,
        time: "NA",
        design: "HEALTHCARE",
        status: "INCEPTION STAGE",
        category: "ARCHITECTURE",
        projectThumb: project__thumb_MOHALLA_CLINIC,
        full_images: [
            project__full_MOHALLA_CLINIC,
            // project__MOHALLA_CLINIC1,
            project__MOHALLA_CLINIC2,
            project__MOHALLA_CLINIC3,
            project__MOHALLA_CLINIC4,
            project__MOHALLA_CLINIC5,
        ],
    },
    {
        id: "arti12",
        desc: "The brief was to design a gate for the famous IT Hub of Kolkata. Having unique features like clear glass skywalk within the City gate Complex, Binary Digits for dynamic shadow patterns in day, Integrated Circuit patterns on its façade, the Architectural Design of this project is already garnering the necessary limelight as the execution phase started.\n",
        name: "The Iconic Gate of NDITA",
        client: "NDITA, GOVT. OF W.B.",
        value: "1.8 CRORES",
        area: "NA",
        time: "NA",
        design: "GATE",
        status: "ONGOING",
        category: "ARCHITECTURE",
        projectThumb: project__thumb_NDITA_ICONIC_GATE,
        full_images: [
            project__full_NDITA_ICONIC_GATE,
            project__NDITA_ICONIC_GATE1,
            project__NDITA_ICONIC_GATE2,
            //   project__NDITA_ICONIC_GATE3
        ],
    },
    {
        id: "arti13",
        desc: "The Stadium like institue is actually a University for Sports and First of its kind in Eastern India.The Project focus on its open spaces within the University along with spaces to play at different heights of the building.",
        name: "NSCB University, Hooghly",
        client: "TECHNO INDIA &amp; GOVT. OF W.B.",
        value: "77 CRORES",
        area: "390000 SQFT",
        time: "NA",
        design: "INSTITUITIONAL",
        status: "INCEPTION STAGE",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_NSCB_UNIVERSITY,
        full_images: [project_full_NSCB_UNIVERSITY_1],
    },
    {
        id: "arti14",
        desc: "One of the first project where no brick was being used for construction. It was challenging given the execution timeline that was provided but served well equally.",
        name: "ONGC Bachelor Accommodation",
        client: "TATA STEEL NESTIN",
        value: "3.1 CRORES",
        area: 9400,
        time: "NA",
        design: "RESIDENTIAL",
        status: "ONGOING",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_BACHELOR_ACCOMODATION_ONGC,
        full_images: [project_full_BACHELOR_ACCOMODATION_ONGC_1, project_full_BACHELOR_ACCOMODATION_ONGC_2],
    },
    {
        id: "arti15",
        desc: "VBRIDGE successfully received credential for Design of the upcoming Terminal Building at the Paro International Airport, Bhutan. The Design of the Project has totally followed the style of Architecture in Bhutan specifically upon client’s requirement and has only used modern elements at the Interior of the Project keeping the values of the local Architecture &amp; Elements intact.\n",
        name: "Paro Internation Airport New Terminus, Bhutan",
        client: "MANDALA CONSULTANTS &amp; GOVT. OF BHUTAN",
        value: "102 CRORES",
        area: 41800,
        time: "NA",
        design: "TRANSPORT",
        status: "ONGOING",
        category: "ARCHITECTURE",
        projectThumb: project__thumb_PARO_AIRPORT,
        full_images: [
            // project__full_PARO_AIRPORT,
            project__PARO_AIRPORT1,
            project__PARO_AIRPORT2,
            project__PARO_AIRPORT3,
        ],
    },
    {
        id: "arti16",
        desc: "The Housing was designed keep the modern look in mind. With commercial space at the bottom, this design bloomed with vibrant colors.",
        name: "Byepass Tower, Kolkata",
        client: "DHARA GROUP",
        value: "2.1 CRORES",
        area: 14200,
        time: "NA",
        design: "MIXED USE",
        status: "INCEPTION STAGE",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_PEERLEES_1,
        full_images: [project_full_PEERLES_1_01, project_full_PEERLES_1_02],
    },
    {
        id: "arti17",
        desc: "The Approach here was to maintain simple lines that a generated on the façade of the building. Also to create dynamic pattern, pergolas were used.",
        name: "Dhara Tower, Kolkata",
        client: "DHARA GROUP",
        value: "1.8 CRORES",
        area: 13500,
        time: "NA",
        design: "MIXED USE",
        status: "INCEPTION STAGE",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_PEERLES_2,
        full_images: [project_full_PEERLES_2_01, project_full_PEERLES_2_02],
    },
    {
        id: "arti18",
        desc: "A totally informal design was proposed for the Site Office of a Highrise group of towers. Given the location &amp; nature surrounding the ideas was to keep a balance of both in mind.",
        name: "Aastha Site Office",
        client: "AASTHA GROUP",
        value: "33 LACS",
        area: 1400,
        time: "NA",
        design: "OFFICE",
        status: "INCEPTION STAGE",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_SITE_OFFICE_AASTHA,
        full_images: [project_full_SITE_OFFICE_AASTHA_1, project_full_SITE_OFFICE_AASTHA_2, project_full_SITE_OFFICE_AASTHA_3],
    },
    {
        id: "arti19",
        desc: "Previously, such a design was already executed and this was an extension to the same but keeping the similar design principles in mind. Use of Glass along with Dry wall makes this design very unique.",
        name: "SLDC Jamshedpur ",
        client: "TATA STEEL NESTIN",
        value: 140800000,
        area: 44000,
        time: "NA",
        design: "OFFICE",
        status: "INCEPTION STAGE",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_SLDC_JAMSHEDPUR,
        full_images: [project_full_SLDC_JAMSHEDPUR_1, project_full_SLDC_JAMSHEDPUR_2, project_full_SLDC_JAMSHEDPUR_3],
    },
    {
        id: "arti20",
        desc: "Exposed brick work feel over dry wall structure was something unique to try. A little away from the mines for the workers family, the hybrid structure has been designed maximizing greenery all along.",
        name: "Noamundi Studio Apartments",
        client: "TATA STEEL NESTIN",
        value: 122400000,
        area: 40800,
        time: "NA",
        design: "RESIDENTIAL",
        status: "ONGOING",
        category: "ARCHITECTURE",
        projectThumb: project_thumb_STUDIO_APARTMENT,
        full_images: [project_full_STUDIO_APARTMENT_1, project_full_STUDIO_APARTMENT_2, project_full_STUDIO_APARTMENT_3],
    },
    {
        id: "arti21",
        desc: "The Hybrid model of using wood &amp; electricity has already been filed for a patent. This has been executed and the last layer of carbon filters reduce pollution levels by 82.56% as studies have revealed in this project.",
        name: "Sustainable Crematoriums",
        client: "GOVT. OF W.B.",
        value: "1.5 CRORE PER UNIT",
        area: 6850,
        time: "5 MONTHS",
        design: "CREMATORIUM",
        status: "COMPLETED",
        category: "ARCHITECTURE",
        projectThumb: project__thumb_CREMATORIUM,
        full_images: [
            project__full_CREMATORIUM,
            project__CREMATORIUM1,
            project__CREMATORIUM2,
            project__CREMATORIUM3,
            project__CREMATORIUM4,
            project__CREMATORIUM5,
        ],
    },
    {
        id: "arti22",
        desc: "Evolution of design took place when VBRIDGE Designed the Façade of a Building using GFRC Panels on walls without bricks. The Technology of façade treatment on Dry walls was thoroughly a major development in this field. This project has been designed &amp; executed at the heart of TATA STEEL PLANT in Jamshedpur. \n",
        name: "TSJ Coke Plant Office &amp; Canteen, Jamshedpur",
        client: "TATA STEEL NESTIN",
        value: "21 CRORES",
        area: 21600,
        time: "9 MONTHS",
        design: "OFFICE",
        status: "COMPLETED",
        category: "ARCHITECTURE",
        projectThumb: project__thumb_TSJ_COKE_PLANT,
        full_images: [
            project__full_TSJ_COKE_PLANT_4,
            // project__full_TSJ_COKE_PLANT,
            project__full_TSJ_COKE_PLANT_1,
            project__full_TSJ_COKE_PLANT_2,
            project__full_TSJ_COKE_PLANT_3,
        ],
    },

    // INSTALLATION projects

    // {
    //     id: "int1",
    //     desc: "The imageability of the Golf Bat is what was required in the Gate Brief from the client's end. Use of Steel in a very dynamic pattern has been the essence of this design.",
    //     name: "Belidih Club (Golf) Gate",
    //     client: "TATA STRUCTURA",
    //     value: "20 LACS",
    //     area: "NA",
    //     time: "NA",
    //     design: "GATE",
    //     status: "ONGOING",
    //     category: "INSTALLATION",
    // },
    {
        id: "int2",
        desc: "Keeping the iconic nature of the Location, the long structure has been designed. It adds to VBRIDGE's list of prestigious installations across the Country.",
        name: "CRC GATE, TARAPORE",
        client: "TATA STRUCTURA",
        value: "22 LACS",
        area: "NA",
        time: "NA",
        design: "GATE",
        status: "ONGOING",
        category: "INSTALLATION",
        projectThumb: project_thumb_CRC_TARAPUR,
        full_images: [project_full_CRC_TARAPUR_1, project_full_CRC_TARAPUR_2, project_full_CRC_TARAPUR_3],
    },
    {
        id: "int3",
        desc: "The 17 kms execution within the city of Kolkata was carried out within 6 months. VBRIDGE created over 450 artworks within 2-3 months to bring this live in the City of Joy. Often it has been stated as one of the longest illusion art in this category.\n\nAnyone who lives in Kolkata or travels from the airport towards Salt Lake City or vice versa, experiences a series of railings that have yellow &amp; grey colored illusion art just like a flipbook or a bioscope moving along with them for almost over 3 kilometers or more. This Project is designed by VBRIDGE for the NKDA, Govt. of WB. Arguably, this is one of the longest illusion art form designed as recommended by a senior professional from DLF.\n",
        name: "Railing Illusion Art, Kolkata",
        client: "NKDA, GOVT. OF W.B.",
        value: "10.1 CRORES",
        area: "OVER 17 KMS",
        time: "6 MONTHS",
        design: "URBAN DESIGN",
        status: "COMPLETED",
        category: "INSTALLATION",
        projectThumb: project_thumb_ILLUSION_ART,
        full_images: [
            project__full_ILLUSION_ART_RAILING,
            project__ILLUSION_ART_RAILING1,
            project__ILLUSION_ART_RAILING2,
            project__ILLUSION_ART_RAILING3,
            project__ILLUSION_ART_RAILING4,
            project__ILLUSION_ART_RAILING5,
            project__ILLUSION_ART_RAILING6,
            project__ILLUSION_ART_RAILING7,
            project__ILLUSION_ART_RAILING8,
            project__ILLUSION_ART_RAILING9,
            project__ILLUSION_ART_RAILING11,
            project__ILLUSION_ART_RAILING12,
        ],
    },
    {
        id: "int4",
        desc: "The Iconic Gate for the Stadium is totally a representation of the several amount of sports played within this arena. It was represents the use of Steel &amp; Tubes in a unique way.",
        name: "JRD Tata Stadium, Entry Gate",
        client: "TATA STRUCTURA",
        value: "1 CRORE",
        area: "NA",
        time: "NA",
        design: "GATE",
        status: "ONGOING",
        category: "INSTALLATION",
        projectThumb: project_thumb_JRD_TATA_GATE,
        full_images: [
            project_full_JRD_TATA_GATE_1,
            project_full_JRD_TATA_GATE_2,
            project_full_JRD_TATA_GATE_3,
            project_full_JRD_TATA_GATE_4,
            project_full_JRD_TATA_GATE_5,
            project_full_JRD_TATA_GATE_6,
        ],
    },
    {
        id: "int5",
        desc: "Another installation by VBRIDGE where the brief talked about family space. The design followed such lines during the ideation. ",
        name: "Tube Makers Club Icon",
        client: "TATA STRUCTURA",
        value: "5 LACS",
        area: "NA",
        time: "NA",
        design: "SCULPTURAL",
        status: "ONGOING",
        category: "INSTALLATION",
        projectThumb: project_thumb_TUBE_MAKERS_CLUB,
        full_images: [project_full_TUBE_MAKERS_CLUB_1, project_full_TUBE_MAKERS_CLUB_2],
    },

    // MODULAR

    {
        id: "modu1",
        desc: "Cricket finds one of its prominent vision from the commentary box. The Unit was installed &amp; designed keeping the flexible requirements of the client as suggested in this developing ground.",
        name: "Commentary Box, Sikkim Cricket Association",
        client: "TATA STEEL NESTIN",
        value: "8 LACS",
        area: 2000,
        time: "1 MONTH",
        design: "RECREATIONAL",
        status: "COMPLETED",
        category: "MODULAR",
        projectThumb: project_thumb_COMMENTRY_BOX_SCA,
        full_images: [project_full_project_thumb_COMMENTRY_BOX_SCA_1, project_full_project_thumb_COMMENTRY_BOX_SCA_2],
    },
    {
        id: "modu2",
        desc: "More than 50+ Covid Hospitals were designed during the COVID Pandemic period with minimum brief from the client &amp; emergency situation. VBRIDGE is proud to have been able to help the community through its profession during pandemic.",
        name: "EMERGENCY COVID HOSPITALS, INDIA",
        client: "TATA STEEL NESTIN",
        value: "AVERAGE COST PER HOSPITAL 3 CRORE",
        area: "AVERAGE 50,000 SQFT-1,50,000 SQFT EACH",
        time: "PER PROJECTS AVERAGE TIME WAS 22 DAYS",
        design: "HEALTHCARE",
        status: "COMPLETED",
        category: "MODULAR",
        projectThumb: project__thumb_COVID_HOSPITAL,
        full_images: [project__full_COVID_HOSPITAL_1, project__full_COVID_HOSPITAL_2, project__full_COVID_HOSPITAL_3, project__full_COVID_HOSPITAL_4],
    },
    {
        id: "modu3",
        desc: "To remain associated as a designer to such a project where the creche facility was being created for the mother &amp; child travelling along the highway. This initiative has been take with the Ministry of Road Transport &amp; Highways, GOI.",
        name: "Creche Facility Unit, India",
        client: "TATA STEEL NESTIN",
        value: "14 LACS",
        area: 800,
        time: "NA",
        design: "COMMERCIAL",
        status: "INCEPTION STAGE",
        category: "MODULAR",
        projectThumb: project_thumb_CRECHE_FACILITY,
        full_images: [project_full_CRECHE_FACILITY_1, project_full_CRECHE_FACILITY_2, project_full_CRECHE_FACILITY_3, project_full_CRECHE_FACILITY_4],
    },
    {
        id: "modu4",
        desc: "Another modular yet crucial project was the NTPC Project. It served one of the most important element of the country for quite a period of time through our design within.",
        name: "NTPC Porta Cabin, India",
        client: "TATA STEEL NESTIN",
        value: "13 LACS",
        area: 200,
        time: "1 MONTH",
        design: "OFFICE",
        status: "COMPLETED",
        category: "MODULAR",
        projectThumb: project_thumb_NTPC_PORTA,
        full_images: [project_full_NTPC_PORTA_1, project_full_NTPC_PORTA_2, project_full_NTPC_PORTA_3, project_full_NTPC_PORTA_4],
    },
    {
        id: "modu5",
        desc: "Green &amp; Sustainable also adds another point - Hygiene. This project was taken up as a mission by VBRIDGE Team and designed keeping smart technologies in mind. ",
        name: "Green Sustainable Toilets, India",
        client: "TATA STEEL NESTIN",
        value: "6 LACS PER UNIT",
        area: 160,
        time: "1 MONTH",
        design: "TOILET",
        status: "COMPLETED",
        category: "MODULAR",
        projectThumb: project_thumb_SMART_MODULAR_TOILET,
        full_images: [
            project_full_SMART_MODULAR_TOILET_1,
            project_full_SMART_MODULAR_TOILET_2,
            project_full_SMART_MODULAR_TOILET_3,
            project_full_SMART_MODULAR_TOILET_4,
            project_full_SMART_MODULAR_TOILET_5,
            project_full_SMART_MODULAR_TOILET_6,
            project_full_SMART_MODULAR_TOILET_7,
            project_full_SMART_MODULAR_TOILET_8,
            project_full_SMART_MODULAR_TOILET_9,
            project_full_SMART_MODULAR_TOILET_10,
            project_full_SMART_MODULAR_TOILET_11,
            project_full_SMART_MODULAR_TOILET_12,
            project_full_SMART_MODULAR_TOILET_13,
            project_full_SMART_MODULAR_TOILET_14,
            project_full_SMART_MODULAR_TOILET_15,
            project_full_SMART_MODULAR_TOILET_16,
        ],
    },

    // TOWNSHIP projets

    {
        id: "twn1",
        desc: "The massive scale of township in the heart of nature had contour lands all across. Lowrise twin cottages form unique identity of this town.",
        name: "Vedanta Township ",
        client: "TATA STEEL NESTIN",
        value: 117000000,
        area: 46800,
        time: "NA",
        design: "RESIDENTIAL",
        status: "ONGOING",
        category: "TOWNSHIP",
        projectThumb: project__thumb_VEDANTA_TOWNSHIP,
        full_images: [project__full_VEDANTA_TOWNSHIP],
    },
    {
        id: "twn2",
        desc: "This was a challenging masterplan design given so many available pockets of land within the proposed township zone. With a township without bricks being created, this design and services have been quite challenging yet solutions keeping the green have been unique &amp; economic.",
        name: "West Bokaro Townsip ",
        client: "TATA STEEL NESTIN",
        value: "222 CRORES",
        area: 631655,
        time: "NA",
        design: "RESIDENTIAL",
        status: "ONGOING",
        category: "TOWNSHIP",
        projectThumb: project_thumb_WEST_BOKARA_TOWNSHIP,
        full_images: [
            project_full_WEST_BOKARA_TOWNSHIP_1,
            project_full_WEST_BOKARA_TOWNSHIP_2,
            project_full_WEST_BOKARA_TOWNSHIP_3,
            project_full_WEST_BOKARA_TOWNSHIP_4,
            project_full_WEST_BOKARA_TOWNSHIP_5,
        ],
    },
];
