import React from "react";
import { motion } from "framer-motion";

const FadeSlide = ({ delayTime, children, uqkey }) => {
    /* ------------------------------------------- */
    /* animation settings */
    /* ------------------------------------------- */
    const animation = {
        initial: { y: 10, opacity: 0 },
        animate: { y: 0, opacity: 1 },
        exit: { y: -10, opacity: 0 },
    };
    /* ------------------------------------------- */
    /* animation settings end*/
    /* ------------------------------------------- */
    return (
        <motion.div
            variants={animation}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.3, delay: delayTime ? delayTime : 0 }}
            key={uqkey}
        >
            {children}
        </motion.div>
    );
};

export default FadeSlide;
