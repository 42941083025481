import React, { useRef, useState } from "react";
import { useFormspark } from "@formspark/use-formspark";
import { IoCheckmarkDoneCircleSharp } from "react-icons/io5";
import CLoader from "../base/loader/CLoader";

const ServiceForm = () => {
    // ref input fields
    const fullNameRef = useRef(null);
    const positionRef = useRef(null);
    const messageRef = useRef(null);
    const emailRef = useRef(null);
    // getting the upload file view on click
    const uploadRef = useRef(null);

    // uploading state
    const [uploading, setUploading] = useState();

    // success state
    const [successMessage, setSuccessMessage] = useState("Send");

    // form values states
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [position, setPosition] = useState("");
    const [message, setMessage] = useState("");
    const [cv, setCv] = useState();

    // upload area clicked function
    const openUpload = () => {
        uploadRef.current.click();
    };

    // handle the upload file

    const uploadHandler = (e) => {
        setCv(e.target.files[0]);
    };

    // form spark
    const FORMSPARK_FORM_ID = "jWj9P7BT";

    const [submit, submitting] = useFormspark({
        formId: FORMSPARK_FORM_ID,
    });

    // career form handler
    const careerFormHandler = async (e) => {
        e.preventDefault();
        try {
            setUploading(true);
            const send = await submit({ fullName, email, position, message, cv });
            if (send) {
                setSuccessMessage("Successfully send");
            }
            e.target.reset();
            setUploading(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            {uploading && <CLoader />}
            <div className="application-form">
                <h2 className="text-display">Send Your Enquiry</h2>
                <form action="" onSubmit={careerFormHandler}>
                    <div className="row">
                        <div className="col-lg-6 px-4">
                            <div className="input-box">
                                <input
                                    ref={fullNameRef}
                                    type="text"
                                    placeholder="Full Name"
                                    name="fullName"
                                    onChange={(e) => setFullName(e.target.value)}
                                />
                                <span class="border"></span>
                            </div>
                            <div className="input-box">
                                <input ref={emailRef} type="email" placeholder="Email" name="email" onChange={(e) => setEmail(e.target.value)} />
                                <span class="border"></span>
                            </div>
                            <div className="input-box">
                                <input ref={emailRef} type="text" placeholder="Phone No" name="phone" onChange={(e) => setEmail(e.target.value)} />
                                <span class="border"></span>
                            </div>
                        </div>
                        <div className="col-lg-6 px-4">
                            <div className="input-box">
                                <select name="reason" id="cars">
                                    <option value="volvo">Select Enquiry Reason</option>
                                    <option value="ARCHITECTURE">ARCHITECTURE</option>
                                    <option value="INTERIOR">INTERIOR</option>
                                    <option value="TOWNSHIP">TOWNSHIP</option>
                                    <option value="MODULAR">MODULAR</option>
                                    <option value="INSTALLATION">INSTALLATION</option>
                                </select>
                                <span class="border"></span>
                            </div>
                            <textarea
                                ref={messageRef}
                                placeholder="Enquiry ( In Brief )"
                                name="message"
                                onChange={(e) => setMessage(e.target.value)}
                            ></textarea>
                            {/* <span class="border"></span> */}
                            {/* </div> */}
                            {successMessage === "Successfully send" ? (
                                <button
                                    className="btn-custom"
                                    disabled="true"
                                    style={{ backgroundColor: "green", color: "white", cursor: "no-drop" }}
                                >
                                    Successful <IoCheckmarkDoneCircleSharp />
                                </button>
                            ) : (
                                <button className="btn-custom" type="submit" disabled={submitting}>
                                    enquire
                                </button>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
};

export default ServiceForm;
