import React from "react";

/**
 * @description Creates a page loader
 * @returns Loader Component
 */
import { loader } from "../../../helpers/Images";

const Loader = () => {
    return (
        <div id="loader-wrapper">
            <div className="loader">
                <img src={loader} alt="loader" />
            </div>
        </div>
    );
};

export default Loader;
