import React from "react";
import { AiFillInstagram } from "react-icons/ai";
import { ImFacebook2, ImLinkedin } from "react-icons/im";
import { IoLogoYoutube } from "react-icons/io";

const FloatingSocial = () => {
    return (
        <div className="floating-social-wrapper">
            <div className="floating-social">
                <div className="social-item">
                    <a style={{ color: "#000" }} href="https://www.facebook.com/abridge2k17/" target="_blank" rel="noreferrer">
                        <ImFacebook2 size={20} />
                    </a>
                </div>
                <div className="social-item">
                    <a style={{ color: "#000" }} href="https://www.instagram.com/vbridgemegacorp/" target="_blank" rel="noreferrer">
                        <AiFillInstagram size={27} />
                    </a>
                </div>
                <div className="social-item">
                    <a style={{ color: "#000" }} href="https://in.linkedin.com/company/vbridge2k17" target="_blank" rel="noreferrer">
                        <ImLinkedin size={22} />
                    </a>
                </div>
                <div className="social-item">
                    <a
                        style={{ color: "#000" }}
                        href="https://www.youtube.com/channel/UCWkj9y0Akz_bHiCWMebzyWA/videos"
                        target="_blank"
                        rel="noreferrer"
                    >
                        <IoLogoYoutube size={25} />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default FloatingSocial;
