import React from "react";
import { Award, FloatingSocial, Wrapper } from "../components";
import { AwardsData } from "../data/AwardsData";
import { Footer, Header } from "../layout";

const Awards = () => {
    return (
        <Wrapper id="awards" pageKey="vbridge__news__page">
            {/* header */}
            <Header />
            {/* header end */}

            {/* awards timeline */}
            <div className="container">
                <h2 className="pb-3 text-display text-center">Awards and Recognition</h2>
                <div className="awards-wrapper">
                    <div className="row">
                        {AwardsData.map((ad) => (
                            <div className="col-lg-6" key={ad.id}>
                                <Award year={ad.year} title={ad.title} place={ad.place} info={ad.info} org={ad.org} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {/* awards timeline end */}

            {/* floating social */}
            <FloatingSocial />
            {/* floating social end */}

            {/* footer */}
            <Footer />
            {/* footer end */}
        </Wrapper>
    );
};

export default Awards;
