import {
    news__2022_1,
    news__2022_2,
    news__2022_3,
    news__2022_4,
    news__2021_5,
    news__2021_6,
    news__2020_7,
    news__2020_8,
    news__2020_9,
    news__2020_10,
    news__2020_11,
    news__2020_12,
    news__2020_13,
    news__2019_15,
    news__2019_16,
    news__latest_adgully,
    news__latest_Chalgenius,
    news__source_Hindware,
    news__source_Josh_Talks,
    news__source_pratidin,
    news__source_tata,
    news__source_Telegraph,
    news__source_The_Better_India,
    news__source_TOI_logo,
    news__source_World_Architecture,
    news__source__Aajkal,
    news__source__CNN_News,
    news__source__Ei_Samay,
    news__source__high_news,
    news__source_chalgeniusp,
    news__source_Adgully,
    news__1,
    news__2,
    news__3,
    news__4,
    news__5,
    news__6,
    news__7,
    news__8,
    news__9,
    news__10,
    news__11,
    news__12,
    news__13,
    news__14,
    news__15,
    news__16,
    news__17,
    news__18,
} from "../helpers/Images";

export const NACat = [{ id: "cat1", cat: "NEWS & RECOGNITIONS" }];
export const NewsYear = [
    { id: "y1", year: "2022" },
    { id: "y2", year: "2021" },
    { id: "y3", year: "2020" },
    { id: "y4", year: "2019" },
];

export const NewsData = [
    {
        year: "SEP 19, 2022",
        title: "Architecture 2.0: The flavour of youth aiming to design the future",
        description: `An architectural start-up formed by two enthusiastic youngsters below 30, VBRIDGE has been constantly in news for its success stories. From the model village designed in Jaugram, covid street art at Konnagar, 3D illusion art guard rails in New Town to covid hospitals across the country, as well as use of steel instead of bricks have already grabbed eyeballs in the recent past.`,
        thumbnail: news__1,
        author: "",
        link: "https://www.adgully.com/architecture-2-0-the-flavour-of-youth-aiming-to-design-the-future-122826.html",
        featured: true,
        source: news__source_Adgully,
        SourceName: "Adgully",
    },
    {
        year: "2022",
        title: "Two young entrepreneurs from West Bengal are making their state proud with their innovative architectural start-up",
        description: `Ayan Roy and. Soumyodeep Das, both under 30, who passed out from IIEST Shibpur in 2017 are changing the ways how buildings are made and designed. They are replacing the traditional brick and mortar structures with steel which is durable and has strong strength.`,
        thumbnail: news__2,
        author: "",
        link: "https://www.chalgenius.com/two-young-entrepreneurs-from-west-bengal-are-making-their-state-proud-with-their-innovative-architectural-start-up/",
        featured: false,
        source: news__source_chalgeniusp,
        SourceName: "CHALGENIUS STARTUPS NEWS",
    },
    {
        year: "MAR 03, 2022",
        title: "The Telegraph: Railing Illusion Art",
        description: `Ideated and designed by VBRIDGE, New Town got eye-catching guard rails with 3D flipbook illusion art on it, as a part of an NKDA endeavour. The artistic railings found a place in The Telegraph, one of the leading dailies of Kolkata, being termed as a tribute to Satyajit Ray. The news described how the railings flaunted a feel of bioscope, presenting the epic Characters and themes of Ray’s films.,`,
        author: "SUBHANKAR CHOWDHURY, SNEHAL SENGUPTA",
        thumbnail: news__3,
        link: "https://www.telegraphindia.com/my-kolkata/news/two-former-iiest-students-pay-tribute-to-satyajit-ray-with-street-art/cid/1855439?fbclid=IwAR3v0_Qsnce3fYFADvCJuVs6bThpDRMjmrXXM55cc-Qs_1WbbZFDWLs4xNg",
        featured: true,
        source: news__source_Telegraph,
        SourceName: "Telegraph",
    },
    {
        year: "Apr 25, 2022",
        title: "Times of India: Railing Illusion Art",
        description: `Our railing illusion art at New Town was praised by one of the most popular dailies, The Times of India as well. The article focused on how the 3km long railing art was ideated and shaped up by VBRIDGE as a part of an NKDA endeavour and has been the talk of the town since. `,
        thumbnail: news__4,
        author: "SUMAN CHAKRABORTI",
        link: "https://timesofindia.indiatimes.com/city/kolkata/new-town-guard-rails-on-3km-stretch-get-3d-flipbook-art/articleshow/91058850.cms",
        featured: false,
        source: news__source_TOI_logo,
        SourceName: "Times Of India",
    },
    {
        year: "Mar 31, 2022",
        title: "Sangbad Pratidin: Railing Illusion Art",
        description: `Sangbad Pratidin, another popular Bengali daily covered our 17km railing illusion art project, focusing on its charm. The article talked about how 3D art is attracting passers-by in zooming cars with its feeling of a bioscope and a flavour of the bygone era.`,
        thumbnail: news__5,
        author: "Akash Misra",
        link: "https://www.sangbadpratidin.in/entertainment/event/popular-artist-paint-bioscope-at-new-town-footpath-railing/",
        featured: false,
        source: news__source_pratidin,
        SourceName: "Sangbad Pratidin",
    },
    {
        year: "2021",
        title: "TEEP: Tata Excellence Recognition",
        description: `Invited to the Tata Education Excellence Programme (TEEP) for the second time in 2022. Our founders, Ar. Ayan Roy and Ar. Soumyodeep Das enchanted the audience all over again at ‘GEAR-UP’, a webinar series for school students, discussing career opportunities in the field of architecture. Both of them received a lot of applause and accolades as guest speakers at the event.`,
        thumbnail: news__6,
        author: "TEEP",
        link: "https://www.linkedin.com/posts/tata-education-excellence-program_education-career-teep-activity-6816610886501904384-y7E5?utm_source=share&utm_medium=member_desktop",
        featured: false,
        source: news__source_tata,
        SourceName: "Tata Education Excellence Programme",
    },
    // 2021
    {
        year: "Jun 26, 2021",
        title: "TOI: London Design Biennale",
        description: `Being awarded at the prestigious London Design 2021 for the Jaugram model village project was a dream come true for Team VBRIDGE. The Covid 19 situation at that time was a hindrance though, preventing our founders Ar. Ayan Roy and Ar. Soumyodeep Das to be present at the event. However, the joy was unparalleled and adding to it was this coverage in the Times of India. The article praised the achievement of the two IIEST ex-students, our founders, for reaching this height, starting from scratch.`,
        thumbnail: news__7,
        author: "JHIMLI MUKHERJEE PANDEY",
        link: "https://timesofindia.indiatimes.com/city/kolkata/iiest-ex-students-model-village-awarded-in-london/articleshow/83851915.cms",
        featured: false,
        source: news__source_TOI_logo,
        SourceName: "Times Of India",
    },
    {
        year: "Jun 3, 2021",
        title: "TEEP: Tata Excellence Recognition",
        description: `We were immensely proud to be invited to the Tata Education Excellence Programme (TEEP) in 2021. Our founders, Ar. Ayan Roy and Ar. Soumyodeep Das were the guest speakers at ‘GEAR-UP’, a webinar series for school students, discussing career opportunities in the field of architecture. Both of them were loved by the audience for their enchanting speech.`,
        thumbnail: news__8,
        author: "",
        link: "https://www.facebook.com/abridge2k17/photos/a.756504001439474/1168764290213441/",
        featured: false,
        source: news__source_tata,
        SourceName: "Tata Education Excellence Programme",
    },
    {
        year: "Oct 28, 2020",
        title: "WAC: Street Art",
        description: `The World Architecture Community recognised our Covid Street Art initiative too. The article on their website phrased it as ‘Art in Everyday Space’ as they described our bit in raising awareness to cope with the deadly battle against the Covid 19 pandemic. A bird’s eye view of the project on this prestigious platform won us accolades from around the world.`,
        thumbnail: news__9,
        author: "Rashi Jain",
        link: "https://worldarchitecture.org/architecture-news/efnzh/art-in-everyday-space-creates-awareness-for-social-distancing-measures-in-kanaipur-west-bengal.html",
        featured: false,
        source: news__source_World_Architecture,
        SourceName: "The World Architecture Community",
    },
    {
        year: "Sep 23, 2020",
        title: "The Next Architect: Hindware",
        description: `Our founders, Ar. Soumyadeep Das and Ar. Ayan Roy were guest speakers at ‘The Next Architect’, presented by Hindware. Based on the topic, ‘Design Icons: Creating unique spatial narratives & new forms of public engagement with the built environment, Team VBRIDGE grabbed the eyeballs with the freshness of its idea and experiments.`,
        thumbnail: news__10,
        author: "",
        link: "https://www.facebook.com/abridge2k17/posts/the-next-architect-2020-vbridge-kolkata-mumbai-new-yorkwebinar-link-httpbitlythe/996984647391407/",
        featured: false,
        source: news__source_Hindware,
        SourceName: "Hindware",
    },
    {
        year: "May 19, 2020",
        title: "Ei Samay: Street Art",
        description: `One of the leading Bengali Dailies, Ei Samay did a story on the Covid Street art at Konnagar by VBRIDGE, lauding us for our attempt to spread awareness. With a focus on how we tried to create mass awareness to help people have a safe journey toward the festive seasons, the article detailed our project and its modus operandi.`,
        thumbnail: news__11,
        author: "VBRIDGE",
        link: "https://www.youtube.com/watch?v=5UEF8pdhJLo",
        featured: false,
        source: news__source__Ei_Samay,
        SourceName: "Ei Samay",
    },
    {
        year: "Sep 24, 2020",
        title: "The Better India: Street Art",
        description: `The renowned news portal, The Better India interviewed Ar. Soumyodeep Das, one of our founder members over the street art project at Konnagar. The video interview had Soumyodeep talking about the project in detail as well as an enchanting view of what we did to spread social distancing awareness in the locality.`,
        thumbnail: news__12,
        author: "",
        link: "https://www.facebook.com/watch/?v=4076494599032497",
        featured: false,
        source: news__source_The_Better_India,
        SourceName: "The Better India",
    },
    {
        year: "June 07, 2020",
        title: "CNN News 18: Street Art",
        description: `One of the most popular news media, CNN News 18 explored our Covid awareness initiative of street art at Konnagar. The article detailed our mission, vision and execution for the same and complimented us for the attempt we made to save the locality from the deadly pandemic.`,
        thumbnail: news__13,
        author: "Shreya Basak",
        link: "https://www.news18.com/news/buzz/bengals-young-architects-have-found-a-way-to-celebrate-social-distance-durga-puja-2647301.html",
        featured: false,
        source: news__source__CNN_News,
        SourceName: "CNN News",
    },
    {
        year: "May 29, 2020",
        title: "Ei Samay: Jaugram Post",
        description: `Another leading Bengali daily Ei Samay covered our Jaugram initiative. Praising our founders, the IIEST alumni Ar. Soumyodeep Das and Ar. Ayan Roy for this endeavour, the article focused on how Team VBRIDGE played a magic wand in transforming the local school building. It also detailed how the project shaped up at Jaugram.`,
        thumbnail: news__14,
        featured: false,
        source: news__source__Ei_Samay,
        author: "",
        link: "https://www.youtube.com/watch?v=ivl_SyvuWC0",
        SourceName: "Ei Samay",
    },
    {
        year: "Jun 01, 2020",
        title: "WAC: Jaugram Post",
        description: `Our model village project at Jaugram not only brought us awards but prestigious recognition by the World Architecture Community too! We were overwhelmed when we got mentioned for our maiden attempt to transform a remote village in India, where rural development is still a far cry.`,
        thumbnail: news__15,
        author: "Rupsha Chakraborty",
        featured: false,
        source: news__source_World_Architecture,
        link: "https://worldarchitecture.org/architecture-news/efhvp/form-follows-transformation-a-reincarnation-strategy-proposed-for-jaugram-village-india.html?fbclid=iwar3s5njldcrlwgifmxcuuicyulbrn1t1ukwuoqkd2epsxkojthzkmiwd-t8",
        SourceName: "The World Architecture Community",
    },
    // 2019
    {
        year: "2019",
        title: "Josh Talks: Journey of VBridge",
        description: `A prestigious digital platform of recent times, Josh Talks invited us to share our journey. From our team, Ar. Ayan Roy joined the show and told our story of how we dreamt of the pinnacle, starting from scratch.`,
        thumbnail: news__16,
        featured: false,
        source: news__source_Josh_Talks,
        author: "",
        link: "",
        SourceName: "Josh Talks",
    },
    {
        year: "June 23, 2019",
        title: "High News: VBridge News",
        description: `VBridge was on High News. One of our founders, Ar. Ayan Roy joined a talk show on the television channel. He talked about architecture and our vision to Bridge the Gap through our initiatives, collaborations, and awareness programmes.`,
        thumbnail: news__17,
        author: "",
        source: news__source__high_news,
        featured: false,
        link: "https://www.facebook.com/abridge2k17/videos/598276834028649/",
        SourceName: "High News",
    },
    {
        year: "Mar 22, 2019",
        title: "Aajkal: Jaugram Post",
        description: `One of the popular newspapers in Kolkata, Aajkal covered our Jaugram project in its initial phase and talked about our aim in detail. It focused on how we attempted redevelopment of the village and the way forward.`,
        thumbnail: news__18,
        author: "",
        featured: false,
        source: news__source__Aajkal,
        link: "https://aajkaal.in/news/state/shibpur-college-student-problem-3jbp",
        SourceName: "Aajkal",
    },
];
